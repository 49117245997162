import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import NotFound404 from '../containers/NotFound/NotFound404'
import MobileLayout from '../mobile/MobileLayout/MobileLayout'
import MobileMainPageSecond from '../mobile/MobileMainPage/MobileMainPageSecond'
import MobileAttendanceForm from '../mobile/mobileAttendance/MobileAttendanceForm'
import MobileBettingHistoryPage from '../mobile/mobileBettingHistory/MobileBettingHistoryPage'
import MobileLiveCasino from '../mobile/mobileCasinoGame/MobileLiveCasino'
import MobileSlotCasino from '../mobile/mobileCasinoGame/MobileSlotCasino'
import MobileCouponUseForm from '../mobile/mobileCouponUse/MobileCouponUseForm'
import MobileEventList from '../mobile/mobileCustomCenter/MobileEvent/MobileEventList'
import MobileNoteList from '../mobile/mobileCustomCenter/MobileNote/MobileNoteList'
import MobileNoticeList from '../mobile/mobileCustomCenter/MobileNotice/MobileNoticeList'
import MobileOnetoOneList from '../mobile/mobileCustomCenter/MobileOnetoOne/MobileOnetoOneList'
import MobileRevolutionHoldemPage from '../mobile/mobileHoldem/MobileRevolutionHoldemPage'
import MobileWildGamesPage from '../mobile/mobileHoldem/MobileWildGamesPage'
import MobileStarLadderOne from '../mobile/mobileMiniGame/MobileBoscore/MobileStarLadderOne'
import MobileStarLadderThree from '../mobile/mobileMiniGame/MobileBoscore/MobileStarLadderThree'
import MobileStarLadderTwo from '../mobile/mobileMiniGame/MobileBoscore/MobileStarLadderTwo'
import MobileGopickBaseball from '../mobile/mobileMiniGame/MobileGopick/MobileGopickBaseball'
import MobileGopickRaccoon from '../mobile/mobileMiniGame/MobileGopick/MobileGopickRaccoon'
import MobileGopickSoccer from '../mobile/mobileMiniGame/MobileGopick/MobileGopickSoccer'
import MobileGopickSquid from '../mobile/mobileMiniGame/MobileGopick/MobileGopickSquid'
import MobileNPowerBallFive from '../mobile/mobileMiniGame/MobileNamed/MobileNPowerBallFive'
import MobileNPowerBallThree from '../mobile/mobileMiniGame/MobileNamed/MobileNPowerBallThree'
import MobileNPowerLadderFive from '../mobile/mobileMiniGame/MobileNamed/MobileNPowerLadderFive'
import MobileNPowerLadderThree from '../mobile/mobileMiniGame/MobileNamed/MobileNPowerLadderThree'
import MobileRedPowerBall from '../mobile/mobileMiniGame/MobileNamed/MobileRedPowerBall'
import MobileRedPowerLadder from '../mobile/mobileMiniGame/MobileNamed/MobileRedPowerLadder'
import MobileRunningBallMazeTwo from '../mobile/mobileMiniGame/MobileNamed/MobileRunningBallMazeTwo'
import MobileRunningBallSpeedFour from '../mobile/mobileMiniGame/MobileNamed/MobileRunningBallSpeedFour'
import MobileRunningBallSpeedSix from '../mobile/mobileMiniGame/MobileNamed/MobileRunningBallSpeedSix'
import MobileEosPowerBallFive from '../mobile/mobileMiniGame/MobileNtry/MobileEosPowerBallFive'
import MobileEosPowerBallFour from '../mobile/mobileMiniGame/MobileNtry/MobileEosPowerBallFour'
import MobileEosPowerBallOne from '../mobile/mobileMiniGame/MobileNtry/MobileEosPowerBallOne'
import MobileEosPowerBallThree from '../mobile/mobileMiniGame/MobileNtry/MobileEosPowerBallThree'
import MobileEosPowerBallTwo from '../mobile/mobileMiniGame/MobileNtry/MobileEosPowerBallTwo'
import MobileEosSpeedKeno from '../mobile/mobileMiniGame/MobileNtry/MobileEosSpeedKeno'
import MobileKenoLadder from '../mobile/mobileMiniGame/MobileNtry/MobileKenoLadder'
import MobileNtryPowerBall from '../mobile/mobileMiniGame/MobileNtry/MobileNtryPowerBall'
import MobilePowerLadder from '../mobile/mobileMiniGame/MobileNtry/MobilePowerLadder'
import MobileSevenBallFive from '../mobile/mobileMiniGame/MobileNtry/MobileSevenBallFive'
import MobileSevenBallOne from '../mobile/mobileMiniGame/MobileNtry/MobileSevenBallOne'
import MobileSevenBallThree from '../mobile/mobileMiniGame/MobileNtry/MobileSevenBallThree'
import MobileSpeedKeno from '../mobile/mobileMiniGame/MobileNtry/MobileSpeedKeno'
import MobileSuremanLadderOne from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanLadderOne'
import MobileSuremanLadderThree from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanLadderThree'
import MobileSuremanLadderTwo from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanLadderTwo'
import MobileSuremanPowerBallOne from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanPowerBallOne'
import MobileSuremanPowerBallThree from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanPowerBallThree'
import MobileSuremanPowerBallTwo from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanPowerBallTwo'
import MobileSuremanRacingOne from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanRacingOne'
import MobileSuremanRacingThree from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanRacingThree'
import MobileSuremanRacingTwo from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanRacingTwo'
import MobileSuremanRoulletOne from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanRoulletOne'
import MobileSuremanRoulletThree from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanRoulletThree'
import MobileSuremanRoulletTwo from '../mobile/mobileMiniGame/MobileSureman/MobileSuremanRoulletTwo'
import MobileTokenGameHiloPage from '../mobile/mobileMiniGame/MobileTokenGame/MobileTokenGameHiloPage'
import MobileTokenGameRoulettePage from '../mobile/mobileMiniGame/MobileTokenGame/MobileTokenGameRoulettePage'
import MobileMoneyDepositForm from '../mobile/mobileMoneyDeposit/MobileMoneyDepositForm'
import MobileMoneyExchangeForm from '../mobile/mobileMoneyExchange/MobileMoneyExchangeForm'
import MobileMoneyWithdrawalForm from '../mobile/mobileMoneyWithdrawal/MobileMoneyWithdrawalForm'
import MobileMyInfo from '../mobile/mobileMyInfo/MobileMyInfo'
import MobileCouponList from '../mobile/mobileMyPage/mobileCouponHistory/MobileCouponList'
import MobileFriendList from '../mobile/mobileMyPage/mobileFriendListHistory/MobileFriendList'
import MobileMoneyDepositList from '../mobile/mobileMyPage/mobileMoneyDepositHistory/MobileMoneyDepositList'
import MobileMoneyExchangeList from '../mobile/mobileMyPage/mobileMoneyExchageHistory/MobileMoneyExchangeList'
import MobileMoneyWithdrawalList from '../mobile/mobileMyPage/mobileMoneyWithdrawalHistory/MobileMoneyWithdrawalList'
import MobilePointDepositList from '../mobile/mobileMyPage/mobilePointDepositHistory/MobilePointDepositList'
import MobilePointExchangeList from '../mobile/mobileMyPage/mobilePointExchangeHistory/MobilePointExchangeList'
import MobilePointExchangeForm from '../mobile/mobilePointExchange/MobilePointExchangeForm'
import MobileGameMoneyPage from '../mobile/mobileSearchGameMoney/MobileGameMoneyPage'
import MobileLiveSportsGamePage from '../mobile/mobileSportsGame/MobileLiveSportsGamePage'
import MobileSportsGamePage from '../mobile/mobileSportsGame/MobileSportsGamePage'
import MobileSportsSpecialGamePage from '../mobile/mobileSportsGame/MobileSportsSpecialGamePage'
import MobileSportsDomesticPage from '../mobile/mobileSportsGame/domestic/MobileSportsDomesticPage'
import MobileSportsLivePage from '../mobile/mobileSportsGame/live/MobileSportsLivePage'
import MoblieSportsOverseasPage from '../mobile/mobileSportsGame/overseas/MoblieSportsOverseasPage'
import MobileSportsSpecialPage from '../mobile/mobileSportsGame/special/MobileSportsSpecialPage'
import MobileVirtualBaseballPage from '../mobile/mobileVirtualGame/Baseball/MobileVirtualBaseballPage'
import MobileVirtualBasketballPage from '../mobile/mobileVirtualGame/Basketball/MobileVirtualBasketballPage'
import MobileVirtualGreyhoundsPage from '../mobile/mobileVirtualGame/Greyhounds/MobileVirtualGreyhoundsPage'
import MobileVirtualHorsePage from '../mobile/mobileVirtualGame/Horse/MobileVirtualHorsePage'
import MobileVirtualSoccerPage from '../mobile/mobileVirtualGame/Soccer/MobileVirtualSoccerPage'
import isLogin from '../utils/enums/authority'
import MobileCrownSutda from '../mobile/mobileMiniGame/MobileCrown/MobileCrownSutda'
import MobileCrownBaccarat from '../mobile/mobileMiniGame/MobileCrown/MobileCrownBaccarat'
import MobileCrownOddEven from '../mobile/mobileMiniGame/MobileCrown/MobileCrownOddEven'
import MobileMgmGoStop from '../mobile/mobileMiniGame/MobileMgm/MobileMgmGoStop'
import MobileMgmBaccarat1 from '../mobile/mobileMiniGame/MobileMgm/MobileMgmBaccarat1'
import MobileMgmOddEven from '../mobile/mobileMiniGame/MobileMgm/MobileMgmOddEven'
import MobileMgmBaccarat2 from '../mobile/mobileMiniGame/MobileMgm/MobileMgmBaccarat2'
import MobileMgmBaccarat3 from '../mobile/mobileMiniGame/MobileMgm/MobileMgmBaccarat3'
import MobileLotusOddEven from '../mobile/mobileMiniGame/Lotus/MobileLotusOddEven'
import MobileLotusBaccarat from '../mobile/mobileMiniGame/Lotus/MobileLotusBaccarat'
import MobileLotusSicbo from '../mobile/mobileMiniGame/Lotus/MobileLotusSicbo'
import MobileCrownNineBall from '../mobile/mobileMiniGame/MobileCrown/MobileCrownNineBall'
import MobileDonghangPowerBall from '../mobile/mobileMiniGame/Donghang/MobileDonghangPowerBall'
import MobileDonghangLadder from '../mobile/mobileMiniGame/Donghang/MobileDonghangLadder'
import MobileNextPowerBall from '../mobile/mobileMiniGame/Next/MobileNextPowerBall'
import MobileNextLadder from '../mobile/mobileMiniGame/Next/MobileNextLadder'
import MobileNextBaccarat from '../mobile/mobileMiniGame/Next/MobileNextBaccarat'
import MobileNextRing from '../mobile/mobileMiniGame/Next/MobileNextRing'
import MobileBepickPowerBall from '../mobile/mobileMiniGame/Bepick/MobileBepickPowerBall'
import MobileBepickLadder from '../mobile/mobileMiniGame/Bepick/MobileBepickLadder'

const PrivateRoute = ({ alertMessage = '로그인 후 이용가능합니다.' }) => {
  if (!isLogin()) {
    return <Navigate to="/" replace state={{ alertMessage }} />
  }

  return <Outlet />
}

// 긴급 점검일때는 url 막아야 함
const MobileRouter = () => {
  return (
    <AnimatePresence>
      <Routes>
        {/* 공통으로 들어갈 컴포넌트 들 */}
        <Route path="/" element={<MobileLayout />}>
          {/* 공통으로 들어갈 컴포넌트 들 */}
          {/* 메인페이지 */}
          <Route index element={<MobileMainPageSecond />} />
          {/* 그외 페이지 */}
          <Route element={<PrivateRoute />}>
            <Route path="/mobile/live-casino" element={<MobileLiveCasino />} />
            <Route path="/mobile/slot-casino" element={<MobileSlotCasino />} />
            <Route path="/mobile/mypage" element={<MobileMyInfo />} />
            <Route path="/mobile/gamemoney" element={<MobileGameMoneyPage />} />
            {/* 머니 신청, 전환 */}
            <Route path="/mobile/money/deposit" element={<MobileMoneyDepositForm />} />
            <Route path="/mobile/money/withdrawal" element={<MobileMoneyWithdrawalForm />} />
            <Route path="/mobile/money/moneyexchange" element={<MobileMoneyExchangeForm />} />
            <Route path="/mobile/money/couponuse" element={<MobileCouponUseForm />} />
            <Route path="/mobile/money/pointexchange" element={<MobilePointExchangeForm />} />
            {/* 마이 페이지 */}
            <Route path="/mobile/pointdepositlist" element={<MobilePointDepositList />} />
            <Route path="/mobile/pointexchangelist" element={<MobilePointExchangeList />} />
            <Route path="/mobile/friendlist" element={<MobileFriendList />} />
            <Route path="/mobile/couponlist" element={<MobileCouponList />} />
            <Route path="/mobile/moneyexchangelist" element={<MobileMoneyExchangeList />} />
            <Route path="/mobile/moneydepositlist" element={<MobileMoneyDepositList />} />
            <Route path="/mobile/moneywithdrawallist" element={<MobileMoneyWithdrawalList />} />
            {/* 출석체크 */}
            <Route path="/mobile/attendance" element={<MobileAttendanceForm />} />
            {/* 스포츠 */}
            <Route path="/mobile/sports" element={<MobileSportsGamePage />} />
            <Route path="/mobile/special-sports" element={<MobileSportsSpecialGamePage />} />
            <Route path="/mobile/live-sports" element={<MobileLiveSportsGamePage />} />
            <Route path="/mobile/sports/domestic" element={<MobileSportsDomesticPage />} />
            <Route path="/mobile/sports/overseas" element={<MoblieSportsOverseasPage />} />
            <Route path="/mobile/sports/special" element={<MobileSportsSpecialPage />} />
            <Route path="/mobile/sports/live" element={<MobileSportsLivePage />} />

            {/* 배팅내역 */}
            <Route path="/mobile/betting-history" element={<MobileBettingHistoryPage />} />
            {/* 고객센터 */}
            <Route path="/mobile/onetoone" element={<MobileOnetoOneList />} />
            <Route path="/mobile/note" element={<MobileNoteList />} />
            <Route path="/mobile/event" element={<MobileEventList />} />
            <Route path="/mobile/notice" element={<MobileNoticeList />} />
            {/* 미니게임 */}
            <Route path="/mobile/mini-game" element={<MobileNtryPowerBall />} />
            <Route path="/mobile/mini-game/ntry/powerladder" element={<MobilePowerLadder />} />
            <Route path="/mobile/mini-game/ntry/kenoladder" element={<MobileKenoLadder />} />
            <Route path="/mobile/mini-game/ntry/speedkeno" element={<MobileSpeedKeno />} />
            <Route path="/mobile/mini-game/ntry/eos1m" element={<MobileEosPowerBallOne />} />
            <Route path="/mobile/mini-game/ntry/eos2m" element={<MobileEosPowerBallTwo />} />
            <Route path="/mobile/mini-game/ntry/eos3m" element={<MobileEosPowerBallThree />} />
            <Route path="/mobile/mini-game/ntry/eos4m" element={<MobileEosPowerBallFour />} />
            <Route path="/mobile/mini-game/ntry/eos5m" element={<MobileEosPowerBallFive />} />
            <Route path="/mobile/mini-game/ntry/eos/speedkeno" element={<MobileEosSpeedKeno />} />
            <Route path="/mobile/mini-game/ntry/seven1m" element={<MobileSevenBallOne />} />
            <Route path="/mobile/mini-game/ntry/seven3m" element={<MobileSevenBallThree />} />
            <Route path="/mobile/mini-game/ntry/seven5m" element={<MobileSevenBallFive />} />
            {/* 보스코어 */}
            <Route path="/mobile/mini-game/boscore/stat1m" element={<MobileStarLadderOne />} />
            <Route path="/mobile/mini-game/boscore/stat2m" element={<MobileStarLadderTwo />} />
            <Route path="/mobile/mini-game/boscore/stat3m" element={<MobileStarLadderThree />} />
            {/* 네임드 */}
            <Route path="/mobile/mini-game/named/n/powerball3m" element={<MobileNPowerBallThree />} />
            <Route path="/mobile/mini-game/named/n/powerball5m" element={<MobileNPowerBallFive />} />
            <Route path="/mobile/mini-game/named/n/powerladder3m" element={<MobileNPowerLadderThree />} />
            <Route path="/mobile/mini-game/named/n/powerladder5m" element={<MobileNPowerLadderFive />} />
            <Route path="/mobile/mini-game/named/red/powerball" element={<MobileRedPowerBall />} />
            <Route path="/mobile/mini-game/named/red/powerladder" element={<MobileRedPowerLadder />} />
            <Route path="/mobile/mini-game/named/running/speed4" element={<MobileRunningBallSpeedFour />} />
            <Route path="/mobile/mini-game/named/running/speed6" element={<MobileRunningBallSpeedSix />} />
            <Route path="/mobile/mini-game/named/running/maze2" element={<MobileRunningBallMazeTwo />} />
            {/* 슈어맨 */}
            <Route path="/mobile/mini-game/sureman/roulette1m" element={<MobileSuremanRoulletOne />} />
            <Route path="/mobile/mini-game/sureman/roulette2m" element={<MobileSuremanRoulletTwo />} />
            <Route path="/mobile/mini-game/sureman/roulette3m" element={<MobileSuremanRoulletThree />} />
            <Route path="/mobile/mini-game/sureman/racing1m" element={<MobileSuremanRacingOne />} />
            <Route path="/mobile/mini-game/sureman/racing2m" element={<MobileSuremanRacingTwo />} />
            <Route path="/mobile/mini-game/sureman/racing3m" element={<MobileSuremanRacingThree />} />
            <Route path="/mobile/mini-game/sureman/ladder1m" element={<MobileSuremanLadderOne />} />
            <Route path="/mobile/mini-game/sureman/ladder2m" element={<MobileSuremanLadderTwo />} />
            <Route path="/mobile/mini-game/sureman/ladder3m" element={<MobileSuremanLadderThree />} />
            <Route path="/mobile/mini-game/sureman/powerball1m" element={<MobileSuremanPowerBallOne />} />
            <Route path="/mobile/mini-game/sureman/powerball2m" element={<MobileSuremanPowerBallTwo />} />
            <Route path="/mobile/mini-game/sureman/powerball3m" element={<MobileSuremanPowerBallThree />} />
            {/* 고픽 */}
            <Route path="/mobile/mini-game/gopick/raccoon" element={<MobileGopickRaccoon />} />
            <Route path="/mobile/mini-game/gopick/soccer" element={<MobileGopickSoccer />} />
            <Route path="/mobile/mini-game/gopick/baseball" element={<MobileGopickBaseball />} />
            <Route path="/mobile/mini-game/gopick/octopus" element={<MobileGopickSquid />} />
            {/* 크라운 */}
            <Route path="/mobile/mini-game/crown/sutda" element={<MobileCrownSutda />} />
            <Route path="/mobile/mini-game/crown/baccarat" element={<MobileCrownBaccarat />} />
            <Route path="/mobile/mini-game/crown/odd-even" element={<MobileCrownOddEven />} />
            <Route path="/mobile/mini-game/crown/nineball" element={<MobileCrownNineBall />} />
            {/* MGM */}
            <Route path="/mobile/mini-game/mgm/go-stop" element={<MobileMgmGoStop />} />
            <Route path="/mobile/mini-game/mgm/baccarat1" element={<MobileMgmBaccarat1 />} />
            <Route path="/mobile/mini-game/mgm/baccarat2" element={<MobileMgmBaccarat2 />} />
            <Route path="/mobile/mini-game/mgm/baccarat3" element={<MobileMgmBaccarat3 />} />
            <Route path="/mobile/mini-game/mgm/odd-even" element={<MobileMgmOddEven />} />
            {/* 로투스 */}
            <Route path="/mobile/mini-game/lotus/odd-even" element={<MobileLotusOddEven />} />
            <Route path="/mobile/mini-game/lotus/baccarat" element={<MobileLotusBaccarat />} />
            <Route path="/mobile/mini-game/lotus/sicbo" element={<MobileLotusSicbo />} />
            {/* 동행 */}
            <Route path="/mobile/mini-game/donghang/powerball" element={<MobileDonghangPowerBall />} />
            <Route path="/mobile/mini-game/donghang/ladder" element={<MobileDonghangLadder />} />
            {/* 넥스트 */}
            <Route path="/mobile/mini-game/next/powerball" element={<MobileNextPowerBall />} />
            <Route path="/mobile/mini-game/next/ladder" element={<MobileNextLadder />} />
            <Route path="/mobile/mini-game/next/baccarat" element={<MobileNextBaccarat />} />
            <Route path="/mobile/mini-game/next/ring" element={<MobileNextRing />} />
            {/* 베픽 */}
            <Route path="/mobile/mini-game/bepick/powerball" element={<MobileBepickPowerBall />} />
            <Route path="/mobile/mini-game/bepick/ladder" element={<MobileBepickLadder />} />

            {/* <Route path="/mobile/mini-game/gopick/evo/powerball1m" element={<MobileGopickEvoPowerball1M />} />
            <Route path="/mobile/mini-game/gopick/evo/powerball2m" element={<MobileGopickEvoPowerball2M />} />
            <Route path="/mobile/mini-game/gopick/evo/powerball3m" element={<MobileGopickEvoPowerball3M />} />
            <Route path="/mobile/mini-game/gopick/evo/powerball4m" element={<MobileGopickEvoPowerball4M />} />
            <Route path="/mobile/mini-game/gopick/evo/powerball5m" element={<MobileGopickEvoPowerball5M />} />
            <Route path="/mobile/mini-game/gopick/evo/powerladder1m" element={<MobileGopickEvoPowerLadder1M />} />
            <Route path="/mobile/mini-game/gopick/evo/powerladder2m" element={<MobileGopickEvoPowerLadder2M />} />
            <Route path="/mobile/mini-game/gopick/evo/powerladder3m" element={<MobileGopickEvoPowerLadder3M />} />
            <Route path="/mobile/mini-game/gopick/evo/powerladder4m" element={<MobileGopickEvoPowerLadder4M />} />
            <Route path="/mobile/mini-game/gopick/evo/powerladder5m" element={<MobileGopickEvoPowerLadder5M />} />
            <Route path="/mobile/mini-game/gopick/evo/dragon" element={<MobileGopickEvoDragon />} />
            <Route path="/mobile/mini-game/gopick/evo/baseball" element={<MobileGopickEvoBaseball />} /> */}

            <Route path="/mobile/token-game/hilo" element={<MobileTokenGameHiloPage />} />
            <Route path="/mobile/token-game/roulette" element={<MobileTokenGameRoulettePage />} />

            <Route path="/mobile/virtual/soccer" element={<MobileVirtualSoccerPage />} />
            <Route path="/mobile/virtual/basketball" element={<MobileVirtualBasketballPage />} />
            <Route path="/mobile/virtual/baseball" element={<MobileVirtualBaseballPage />} />
            <Route path="/mobile/virtual/greyhounds" element={<MobileVirtualGreyhoundsPage />} />
            <Route path="/mobile/virtual/horse" element={<MobileVirtualHorsePage />} />

            <Route path="/mobile/holdem/revolution" element={<MobileRevolutionHoldemPage />} />
            <Route path="/mobile/holdem/wild-games" element={<MobileWildGamesPage />} />
          </Route>

          {/* <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/sign-up" element={<SignUpPage />} /> */}
        </Route>
        {/* 공통 Layout 이 안보여야 하는 컴포넌트 들 */}
        {/* 아래는 페이지 not found  */}
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </AnimatePresence>
  )
}

export default MobileRouter
