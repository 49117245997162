import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import AttendanceForm from '../containers/Attendance/AttendanceForm'
import BettingHistoryPage from '../containers/BettingHistory/BettingHistoryPage'
import LiveCasino from '../containers/CasinoGame/LiveCasino'
import SlotCasino from '../containers/CasinoGame/SlotCasino'
import CouponUseForm from '../containers/CouponUse/CouponUseForm'
import EventListPage from '../containers/CustomCenter/Event/EventListPage'
import NoteList from '../containers/CustomCenter/Note/NoteList'
import NoticeListPage from '../containers/CustomCenter/Notice/NoticeListPage'
import OnetoOneList from '../containers/CustomCenter/OnetoOne/OnetoOneList'
import RevolutionHoldemPage from '../containers/Holdem/RevolutionHoldemPage'
import WildGamesPage from '../containers/Holdem/WildGamesPage'
import Layout from '../containers/Layout/Layout'
import MainPage from '../containers/MainPage/MainPage'
import StarLadderOne from '../containers/MiniGame/Boscore/StarLadderOne'
import StarLadderThree from '../containers/MiniGame/Boscore/StarLadderThree'
import StarLadderTwo from '../containers/MiniGame/Boscore/StarLadderTwo'
import GopickBaseball from '../containers/MiniGame/Gopick/GopickBaseball'
import GopickRaccoon from '../containers/MiniGame/Gopick/GopickRaccoon'
import GopickSoccer from '../containers/MiniGame/Gopick/GopickSoccer'
import GopickSquid from '../containers/MiniGame/Gopick/GopickSquid'
import NPowerBallFive from '../containers/MiniGame/Named/NPowerBallFive'
import NPowerBallThree from '../containers/MiniGame/Named/NPowerBallThree'
import NPowerLadderFive from '../containers/MiniGame/Named/NPowerLadderFive'
import NPowerLadderThree from '../containers/MiniGame/Named/NPowerLadderThree'
import RedPowerBall from '../containers/MiniGame/Named/RedPowerBall'
import RedPowerLadder from '../containers/MiniGame/Named/RedPowerLadder'
import RunningBallMazeTwo from '../containers/MiniGame/Named/RunningBallMazeTwo'
import RunningBallSpeedFour from '../containers/MiniGame/Named/RunningBallSpeedFour'
import RunningBallSpeedSix from '../containers/MiniGame/Named/RunningBallSpeedSix'
import EosPowerBallFive from '../containers/MiniGame/Ntry/EosPowerBallFive'
import EosPowerBallFour from '../containers/MiniGame/Ntry/EosPowerBallFour'
import EosPowerBallOne from '../containers/MiniGame/Ntry/EosPowerBallOne'
import EosPowerBallThree from '../containers/MiniGame/Ntry/EosPowerBallThree'
import EosPowerBallTwo from '../containers/MiniGame/Ntry/EosPowerBallTwo'
import EosSevenBallFive from '../containers/MiniGame/Ntry/EosSevenBallFive'
import EosSevenBallOne from '../containers/MiniGame/Ntry/EosSevenBallOne'
import EosSevenBallThree from '../containers/MiniGame/Ntry/EosSevenBallThree'
import EosSpeedKeno from '../containers/MiniGame/Ntry/EosSpeedKeno'
import KenoLadder from '../containers/MiniGame/Ntry/KenoLadder'
import NtryPowerBall from '../containers/MiniGame/Ntry/NtryPowerBall'
import PowerLadder from '../containers/MiniGame/Ntry/PowerLadder'
import SpeedKeno from '../containers/MiniGame/Ntry/SpeedKeno'
import SuremanLadderOne from '../containers/MiniGame/Sureman/SuremanLadderOne'
import SuremanLadderThree from '../containers/MiniGame/Sureman/SuremanLadderThree'
import SuremanLadderTwo from '../containers/MiniGame/Sureman/SuremanLadderTwo'
import SuremanPowerBallOne from '../containers/MiniGame/Sureman/SuremanPowerBallOne'
import SuremanPowerBallThree from '../containers/MiniGame/Sureman/SuremanPowerBallThree'
import SuremanPowerBallTwo from '../containers/MiniGame/Sureman/SuremanPowerBallTwo'
import SuremanRacingOne from '../containers/MiniGame/Sureman/SuremanRacingOne'
import SuremanRacingThree from '../containers/MiniGame/Sureman/SuremanRacingThree'
import SuremanRacingTwo from '../containers/MiniGame/Sureman/SuremanRacingTwo'
import SuremanRoulletOne from '../containers/MiniGame/Sureman/SuremanRoulletOne'
import SuremanRoulletThree from '../containers/MiniGame/Sureman/SuremanRoulletThree'
import SuremanRoulletTwo from '../containers/MiniGame/Sureman/SuremanRoulletTwo'
import TokenGameHiloPage from '../containers/MiniGame/TokenGame/TokenGameHiloPage'
import TokenGameRoulettePage from '../containers/MiniGame/TokenGame/TokenGameRoulettePage'
import MoneyDepositForm from '../containers/MoneyDeposit/MoneyDepositForm'
import MoneyExchangeForm from '../containers/MoneyExchange/MoneyExchangeForm'
import MoneyWithdrawalForm from '../containers/MoneyWithdrawal/MoneyWithdrawalForm'
import FriendList from '../containers/MyPage/FriendListHistory/FriendList'
import MyInfo from '../containers/MyPage/MyInfo/MyInfo'
import NotFound404 from '../containers/NotFound/NotFound404'
import PointExchangeForm from '../containers/PointExchange/PointExchangeForm'
import GameMoneyPage from '../containers/SearchGameMoney/GameMoneyPage'
import SportsDomesticPage from '../containers/SportsGame/domestic/SportsDomesticPage'
import SportsLivePage from '../containers/SportsGame/live/SportsLivePage'
import SportsOverseasPage from '../containers/SportsGame/overseas/SportsOverseasPage'
import SportsSpecialPage from '../containers/SportsGame/special/SportsSpecialPage'
import VirtualBaseballPage from '../containers/VirtualGame/Baseball/VirtualBaseballPage'
import VirtualBasketballPage from '../containers/VirtualGame/Basketball/VirtualBasketballPage'
import VirtualGreyhoundsPage from '../containers/VirtualGame/Greyhounds/VirtualGreyhoundsPage'
import VirtualHorsePage from '../containers/VirtualGame/Horse/VirtualHorsePage'
import VirtualSoccerPage from '../containers/VirtualGame/Soccer/VirtualSoccerPage'
import isLogin from '../utils/enums/authority'
import CrownSutda from '../containers/MiniGame/Crown/CrownSutda'
import CrownBaccarat from '../containers/MiniGame/Crown/CrownBaccarat'
import CrownOddEven from '../containers/MiniGame/Crown/CrownOddEven'
import CrownNineBall from '../containers/MiniGame/Crown/CrownNineBall'
import MgmGoStop from '../containers/MiniGame/Mgm/MgmGoStop'
import MgmBaccarat1 from '../containers/MiniGame/Mgm/MgmBaccarat1'
import MgmBaccarat3 from '../containers/MiniGame/Mgm/MgmBaccarat3'
import MgmBaccarat2 from '../containers/MiniGame/Mgm/MgmBaccarat2'
import MgmOddEven from '../containers/MiniGame/Mgm/MgmOddEven'
import LotusOddEven from '../containers/MiniGame/Lotus/LotusOddEven'
import LotusBaccarat from '../containers/MiniGame/Lotus/LotusBaccarat'
import LotusSicbo from '../containers/MiniGame/Lotus/LotusSicbo'
import DonghangPowerBall from '../containers/MiniGame/Donghang/DonghangPowerBall'
import DonghangPowerLadder from '../containers/MiniGame/Donghang/DonghangPowerLadder'
import NextPowerBall from '../containers/MiniGame/Next/NextPowerBall'
import NextLadder from '../containers/MiniGame/Next/NextLadder'
import NextBaccarat from '../containers/MiniGame/Next/NextBaccarat'
import NextRing from '../containers/MiniGame/Next/NextRing'
import BePickPowerBall from '../containers/MiniGame/Bepick/BePickPowerBall'
import BePickLadder from '../containers/MiniGame/Bepick/BePickLadder'

const PrivateRoute = ({ alertMessage = '로그인 후 이용가능합니다.' }) => {
  if (isMobile || !isLogin()) {
    return <Navigate to="/" replace state={{ alertMessage }} />
  }

  return <Outlet />
}

// 긴급 점검일때는 url 막아야 함
const Router = () => {
  return (
    <AnimatePresence>
      <Routes>
        {/* 공통으로 들어갈 컴포넌트 들 */}
        <Route element={<Layout />}>
          {/* 공통으로 들어갈 컴포넌트 들 */}
          {/* 메인페이지 */}
          <Route index element={<MainPage />} />
          {/* 그외 페이지 */}
          <Route element={<PrivateRoute />}>
            <Route path="/live-casino" element={<LiveCasino />} />
            <Route path="/slot-casino" element={<SlotCasino />} />
            <Route path="/mypage" element={<MyInfo />} />
            <Route path="/gamemoney" element={<GameMoneyPage />} />
            <Route path="/money/deposit" element={<MoneyDepositForm />} />
            <Route path="/money/withdrawal" element={<MoneyWithdrawalForm />} />
            <Route path="/money/moneyexchange" element={<MoneyExchangeForm />} />
            <Route path="/money/couponuse" element={<CouponUseForm />} />
            <Route path="/money/pointexchange" element={<PointExchangeForm />} />
            <Route path="/customcenter/notice" element={<NoticeListPage />} />
            <Route path="/customcenter/event" element={<EventListPage />} />

            <Route path="/minigame" element={<NtryPowerBall />} />
            <Route path="/minigame/ntry/powerladder" element={<PowerLadder />} />
            <Route path="/minigame/ntry/kenoladder" element={<KenoLadder />} />
            <Route path="/minigame/ntry/speedkeno" element={<SpeedKeno />} />
            <Route path="/minigame/ntry/eos/speedkeno" element={<EosSpeedKeno />} />
            {/* <Route path="/minigame/ntry/eos/nine" element={<EosNineBall />} /> */}
            <Route path="/minigame/ntry/seven1m" element={<EosSevenBallOne />} />
            <Route path="/minigame/ntry/seven3m" element={<EosSevenBallThree />} />
            <Route path="/minigame/ntry/seven5m" element={<EosSevenBallFive />} />
            <Route path="/minigame/ntry/eos1m" element={<EosPowerBallOne />} />
            <Route path="/minigame/ntry/eos2m" element={<EosPowerBallTwo />} />
            <Route path="/minigame/ntry/eos3m" element={<EosPowerBallThree />} />
            <Route path="/minigame/ntry/eos4m" element={<EosPowerBallFour />} />
            <Route path="/minigame/ntry/eos5m" element={<EosPowerBallFive />} />
            {/* 보스코어 */}
            <Route path="/minigame/boscore/stat1m" element={<StarLadderOne />} />
            <Route path="/minigame/boscore/stat2m" element={<StarLadderTwo />} />
            <Route path="/minigame/boscore/stat3m" element={<StarLadderThree />} />
            {/* 네임드 */}
            <Route path="/minigame/named/n/powerball3m" element={<NPowerBallThree />} />
            <Route path="/minigame/named/n/powerball5m" element={<NPowerBallFive />} />
            <Route path="/minigame/named/n/powerladder3m" element={<NPowerLadderThree />} />
            <Route path="/minigame/named/n/powerladder5m" element={<NPowerLadderFive />} />
            <Route path="/minigame/named/red/powerball" element={<RedPowerBall />} />
            <Route path="/minigame/named/red/powerladder" element={<RedPowerLadder />} />
            <Route path="/minigame/named/running/speed4" element={<RunningBallSpeedFour />} />
            <Route path="/minigame/named/running/speed6" element={<RunningBallSpeedSix />} />
            <Route path="/minigame/named/running/maze2" element={<RunningBallMazeTwo />} />
            {/* 동행 */}
            <Route path="/minigame/donghang/powerball" element={<DonghangPowerBall />} />
            <Route path="/minigame/donghang/powerladder" element={<DonghangPowerLadder />} />
            {/* 슈어맨 */}
            <Route path="/minigame/sureman/ladder1m" element={<SuremanLadderOne />} />
            <Route path="/minigame/sureman/ladder2m" element={<SuremanLadderTwo />} />
            <Route path="/minigame/sureman/ladder3m" element={<SuremanLadderThree />} />
            <Route path="/minigame/sureman/powerball1m" element={<SuremanPowerBallOne />} />
            <Route path="/minigame/sureman/powerball2m" element={<SuremanPowerBallTwo />} />
            <Route path="/minigame/sureman/powerball3m" element={<SuremanPowerBallThree />} />
            <Route path="/minigame/sureman/roulette1m" element={<SuremanRoulletOne />} />
            <Route path="/minigame/sureman/roulette2m" element={<SuremanRoulletTwo />} />
            <Route path="/minigame/sureman/roulette3m" element={<SuremanRoulletThree />} />
            <Route path="/minigame/sureman/racing1m" element={<SuremanRacingOne />} />
            <Route path="/minigame/sureman/racing2m" element={<SuremanRacingTwo />} />
            <Route path="/minigame/sureman/racing3m" element={<SuremanRacingThree />} />
            {/* 고픽 */}
            <Route path="/minigame/gopick/raccoon" element={<GopickRaccoon />} />
            <Route path="/minigame/gopick/soccer" element={<GopickSoccer />} />
            <Route path="/minigame/gopick/baseball" element={<GopickBaseball />} />
            <Route path="/minigame/gopick/octopus" element={<GopickSquid />} />
            {/* 크라운 */}
            <Route path="/minigame/crown/sutda" element={<CrownSutda />} />
            <Route path="/minigame/crown/baccarat" element={<CrownBaccarat />} />
            <Route path="/minigame/crown/odd-even" element={<CrownOddEven />} />
            <Route path="/minigame/crown/nine-ball" element={<CrownNineBall />} />
            {/* MGM */}
            <Route path="/minigame/mgm/go-stop" element={<MgmGoStop />} />
            <Route path="/minigame/mgm/baccarat1" element={<MgmBaccarat1 />} />
            <Route path="/minigame/mgm/baccarat2" element={<MgmBaccarat2 />} />
            <Route path="/minigame/mgm/baccarat3" element={<MgmBaccarat3 />} />
            <Route path="/minigame/mgm/odd-even" element={<MgmOddEven />} />
            {/* 로투스 */}
            <Route path="/minigame/lotus/odd-even" element={<LotusOddEven />} />
            <Route path="/minigame/lotus/baccarat" element={<LotusBaccarat />} />
            <Route path="/minigame/lotus/sicbo" element={<LotusSicbo />} />
            {/* 넥스트 */}
            <Route path="/minigame/next/powerball" element={<NextPowerBall />} />
            <Route path="/minigame/next/ladder" element={<NextLadder />} />
            <Route path="/minigame/next/baccarat" element={<NextBaccarat />} />
            <Route path="/minigame/next/ring" element={<NextRing />} />
            {/* 베픽 */}
            <Route path="/minigame/bepick/powerball" element={<BePickPowerBall />} />
            <Route path="/minigame/bepick/ladder" element={<BePickLadder />} />
            {/* <Route path="/minigame/gopick/evo/powerball1m" element={<GopickEvoPowerballOne />} />
            <Route path="/minigame/gopick/evo/powerball2m" element={<GopickEvoPowerballTwo />} />
            <Route path="/minigame/gopick/evo/powerball3m" element={<GopickEvoPowerballThree />} />
            <Route path="/minigame/gopick/evo/powerball4m" element={<GopickEvoPowerballFour />} />
            <Route path="/minigame/gopick/evo/powerball5m" element={<GopickEvoPowerballFive />} />
            <Route path="/minigame/gopick/evo/powerladder1m" element={<GopickEvoPowerLadderOne />} />
            <Route path="/minigame/gopick/evo/powerladder2m" element={<GopickEvoPowerLadderTwo />} />
            <Route path="/minigame/gopick/evo/powerladder3m" element={<GopickEvoPowerLadderThree />} />
            <Route path="/minigame/gopick/evo/powerladder4m" element={<GopickEvoPowerLadderFour />} />
            <Route path="/minigame/gopick/evo/powerladder5m" element={<GopickEvoPowerLadderFive />} />
            <Route path="/minigame/gopick/evo/dragon" element={<GopickEvoDragon />} />
            <Route path="/minigame/gopick/evo/baseball" element={<GopickEvoBaseball />} /> */}

            <Route path="/token-game/hilo" element={<TokenGameHiloPage />} />
            <Route path="/token-game/roulette" element={<TokenGameRoulettePage />} />
            <Route path="/sports/domestic" element={<SportsDomesticPage />} />
            <Route path="/sports/overseas" element={<SportsOverseasPage />} />
            <Route path="/sports/special" element={<SportsSpecialPage />} />
            <Route path="/sports/live" element={<SportsLivePage />} />

            <Route path="/virtual/soccer" element={<VirtualSoccerPage />} />
            <Route path="/virtual/basketball" element={<VirtualBasketballPage />} />
            <Route path="/virtual/baseball" element={<VirtualBaseballPage />} />
            <Route path="/virtual/greyhounds" element={<VirtualGreyhoundsPage />} />
            <Route path="/virtual/horse" element={<VirtualHorsePage />} />

            <Route path="/holdem/revolution" element={<RevolutionHoldemPage />} />
            <Route path="/holdem/wild-games" element={<WildGamesPage />} />

            <Route path="/attendance" element={<AttendanceForm />} />
            <Route path="/betting-history" element={<BettingHistoryPage />} />
            <Route path="/one-to-one" element={<OnetoOneList />} />
            <Route path="/note" element={<NoteList />} />
            <Route path="/friend-list" element={<FriendList />} />
          </Route>
          {/* <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/sign-up" element={<SignUpPage />} /> */}
        </Route>
        {/* 공통 Layout 이 안보여야 하는 컴포넌트 들 */}
        {/* 아래는 페이지 not found  */}
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </AnimatePresence>
  )
}

export default Router
