import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 헤더 조회 API
export async function searchBannerList(params) {
  const response = await axios({
    url: '/tyson/v1/banner/member/banner-list',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
