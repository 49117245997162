import isEqual from 'lodash/isEqual'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import { addBettingCartItem } from '../../../redux/sportsBettingSlice'
import {
  fetchLiveFixtureListAction,
  fetchLiveFixtureMarketAction,
  setFixtureCollapse,
  setFixtureMarketParam,
  setPageNum,
} from '../../../redux/sportsLiveInfoSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import { SportMarketType } from '../../../utils/enums/SportsGame/SportsGameEnums'
import SportsLiveContentMarket from './SportsLiveContentMarket'
import { SportEventInProgressFixtures } from './SportEventInProgressFixtures'
import { SportEventNotInProgressFixtures } from './SportEventNotInProgressFixtures'

const SportsLiveContent = () => {
  const dispatch = useDispatch()

  const { totalElements, liveParams, liveMarketParams, liveFixtures } = useSelector(state => {
    const { sportsLiveInfo } = state

    return {
      totalElements: sportsLiveInfo.totalElements,
      liveParams: sportsLiveInfo.liveParams,
      liveMarketParams: sportsLiveInfo.liveMarketParams,
      liveFixtures: sportsLiveInfo.liveFixtures,
    }
  })

  const [liveInProgressFixtures, setLiveInProgressFixtures] = useState({})
  const [liveNotInProgressFixtures, setLiveNotInProgressFixtures] = useState({})

  useEffect(() => {
    const tempLiveInProgressFixtures = {}
    const tempLiveNotInProgressFixtures = {}

    Object.entries(liveFixtures).forEach(([key, event]) => {
      // 현재 이벤트의 fixtures를 분류
      const inProgressFixtures = event.fixtures.filter(fixtureItem => {
        return (
          fixtureItem.fixture.fixtureStatus === 'IN_PROGRESS' || fixtureItem.fixture.fixtureStatus === 'ABOUT_TO_START'
        )
      })

      const notInProgressFixtures = event.fixtures.filter(fixtureItem => {
        return (
          fixtureItem.fixture.fixtureStatus !== 'IN_PROGRESS' && fixtureItem.fixture.fixtureStatus !== 'ABOUT_TO_START'
        )
      })

      // 분류된 fixtures가 존재하면 임시 객체에 추가
      if (inProgressFixtures.length > 0) {
        tempLiveInProgressFixtures[key] = {
          ...event,
          fixtures: inProgressFixtures,
        }
      }

      if (notInProgressFixtures.length > 0) {
        tempLiveNotInProgressFixtures[key] = {
          ...event,
          fixtures: notInProgressFixtures,
        }
      }
    })

    setLiveInProgressFixtures(tempLiveInProgressFixtures)
    setLiveNotInProgressFixtures(tempLiveNotInProgressFixtures)
  }, [liveFixtures])

  const { bettingCartList, bettingOkTimeCriteria } = useSelector(state => {
    const { sportsBetting } = state

    const sportsBettingInfo = sportsBetting.sportsBettingInfos[SportMarketType.LIVE]

    return {
      bettingCartList: sportsBetting.bettingCartList,
      bettingOkTimeCriteria: sportsBettingInfo?.bettingOkTimeCriteria ?? 0,
    }
  })

  const bettingCartListRef = useRef(bettingCartList)

  useEffect(() => {
    bettingCartListRef.current = bettingCartList
  }, [bettingCartList])

  const sportsEventListWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / liveParams.size))
    if (sportsEventListWrapRef.current) {
      sportsEventListWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }, [totalElements, liveParams])

  const onPageChange = pageNumber => {
    dispatch(setPageNum(pageNumber))
  }

  useEffect(() => {
    dispatch(fetchLiveFixtureListAction())
  }, [dispatch, liveParams])

  useEffect(() => {
    dispatch(fetchLiveFixtureMarketAction())
  }, [dispatch, liveMarketParams])

  // 경기 리그 펼치기
  const onClickFixtureCollapseHandler = useCallback(
    key => {
      dispatch(setFixtureCollapse(key))
    },
    [dispatch],
  )

  const [selectedFixtureInfo, setSelectedFixtureInfo] = useState(null)
  const selectedFixtureInfoRef = useRef(selectedFixtureInfo)

  useEffect(() => {
    selectedFixtureInfoRef.current = selectedFixtureInfo
  }, [selectedFixtureInfo])

  const onClickSportFixtureMarketAdditionalOptionHandler = useCallback(
    (eventKey, fixtureData) => {
      const diff = !isEqual(selectedFixtureInfoRef.current, {
        eventKey,
        fixtureId: fixtureData.fixtureId,
      })

      if (diff) {
        dispatch(setFixtureMarketParam(fixtureData.fixtureId))
        setSelectedFixtureInfo({
          eventKey,
          fixtureId: fixtureData.fixtureId,
        })
      } else {
        setSelectedFixtureInfo(null)
      }
    },
    [dispatch],
  )

  // 배팅 카트 담기
  const onClickAddBettingCartHandler = useCallback(
    bettingCartItem => {
      dispatch(
        addBettingCartItem({
          ...bettingCartItem,
          sportMarketType: SportMarketType.LIVE,
        }),
      )
    },
    [dispatch],
  )

  return (
    <SportsContentWrap>
      <SportsEventListWrap ref={sportsEventListWrapRef}>
        {Object.keys(liveFixtures) === 0 ? (
          <SportsGameEmptyInfoBox key={`empty-${liveParams.sportId}-${liveParams.locationId}`}>
            해당 조건에 검색되는 경기가 없습니다.
          </SportsGameEmptyInfoBox>
        ) : (
          <>
            {Object.keys(liveInProgressFixtures).length !== 0 && (
              <SportsInProgressWrap>
                {Object.entries(liveInProgressFixtures).map(([key, event]) => {
                  const filteredFixtures = event.fixtures.filter(fixtureItem => {
                    return (
                      fixtureItem.fixture.fixtureVisible &&
                      fixtureItem.fixture.sportVisible &&
                      fixtureItem.fixture.locationVisible &&
                      fixtureItem.fixture.leagueVisible &&
                      fixtureItem.fixture.homeVisible &&
                      fixtureItem.fixture.awayVisible &&
                      (fixtureItem.fixture.fixtureStatus === 'IN_PROGRESS' ||
                        fixtureItem.fixture.fixtureStatus === 'ABOUT_TO_START')
                    )
                  })

                  if (filteredFixtures.length === 0) return null

                  const customEvent = {
                    ...event,
                    fixtures: [...filteredFixtures],
                  }

                  return (
                    <SportEventInProgressFixtures
                      eventKey={key}
                      event={customEvent}
                      onClickFixtureCollapseHandler={onClickFixtureCollapseHandler}
                      onClickSportFixtureMarketAdditionalOptionHandler={
                        onClickSportFixtureMarketAdditionalOptionHandler
                      }
                      onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                      selectedFixtureId={selectedFixtureInfo?.fixtureId}
                    />
                  )
                })}
              </SportsInProgressWrap>
            )}

            {Object.keys(liveNotInProgressFixtures).length !== 0 && (
              <SportsNotInProgressWrap>
                <SportsNotInProgressTextBox>진행예정경기</SportsNotInProgressTextBox>
                {Object.entries(liveNotInProgressFixtures).map(([key, event]) => {
                  const filteredFixtures = event.fixtures.filter(fixtureItem => {
                    return (
                      fixtureItem.fixture.fixtureVisible &&
                      fixtureItem.fixture.sportVisible &&
                      fixtureItem.fixture.locationVisible &&
                      fixtureItem.fixture.leagueVisible &&
                      fixtureItem.fixture.homeVisible &&
                      fixtureItem.fixture.awayVisible &&
                      fixtureItem.fixture.fixtureStatus === 'NSY'
                    )
                  })

                  if (filteredFixtures.length === 0) return null

                  const customEvent = {
                    ...event,
                    fixtures: [...filteredFixtures],
                  }
                  return (
                    <SportEventNotInProgressFixtures
                      eventKey={key}
                      event={customEvent}
                      onClickFixtureCollapseHandler={onClickFixtureCollapseHandler}
                    />
                  )
                })}
              </SportsNotInProgressWrap>
            )}

            <PaginationBox>
              <CustomPagination currentPage={liveParams.page} totalPages={totalPages} onPageChange={onPageChange} />
            </PaginationBox>
          </>
        )}
      </SportsEventListWrap>

      <SportsLiveContentMarket selectedFixtureInfo={selectedFixtureInfo} />
    </SportsContentWrap>
  )
}

export default SportsLiveContent

const SportsContentWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`

const SportsEventListWrap = styled.div`
  width: 100%;
  height: calc(100vh - 167px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 0px;
  }
`

// 경기가 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const SportsInProgressWrap = styled.div`
  width: 100%;
  display: block;
`

const SportsNotInProgressWrap = styled.div`
  width: 100%;
  display: block;
`

// 진행예정경기 문구
const SportsNotInProgressTextBox = styled.div`
  display: block;
  padding: 20px;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  color: #fff;
  margin-top: 20px;
  background: rgb(29, 30, 33);
`

// 페이징
const PaginationBox = styled.div`
  width: 100%;
`
