import 'moment/locale/ko'
import React, { useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FaLock } from 'react-icons/fa'
import { HiOutlineRefresh } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { bettingSportsEventV2 } from '../../api/game/sportsGameApi'
import { withdrawCasinoMoney } from '../../api/memberManage/memberManageApi'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { onShowModalHandler } from '../../redux/modalSlice'
import {
  onClickBettingCartAlertCloseHandler,
  onClickBettingCartUpdateMessageCloseHandler,
  removeAllBettingCartItem,
  removeBettingCartItem,
  setActiveSportsMarketType,
  setBettingCartLoading,
  setBettingDelay,
  setSelectedBonusFolder,
} from '../../redux/sportsBettingSlice'
import { setDomesticActiveBetInfo } from '../../redux/sportsDomesticInfoSlice'
import { setLiveActiveBetInfo } from '../../redux/sportsLiveInfoSlice'
import { setOverseasActiveBetInfo } from '../../redux/sportsOverseasInfoSlice'
import { setSpecialActiveBetInfo } from '../../redux/sportsSpecialInfoSlice'
import { handleDeleteAuthentication } from '../../socketio/userAuthSocketIOHandler'
import { SportMarketType } from '../../utils/enums/SportsGame/SportsGameEnums'
import isLogin from '../../utils/enums/authority'
import { HermesMainBorder, HermesMainHover, HermesMainHover2, HermesMainText } from '../../utils/palette'
import { decodeAccessToken, deleteAccessToken, getAccessToken } from '../../utils/token'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomAlert from '../CustomAlert'
import CustomBettingAlert from '../CustomBettingAlert'
import BettingCartFormField from './BettingCartFormField'

const SportsBettingCartSidebar = () => {
  const navigate = useNavigate()
  const [loginCheck, setLoginCheck] = useState(false)

  useEffect(() => {
    setLoginCheck(isLogin())
  }, [isLogin()])

  // redux 사용
  const dispatch = useDispatch()

  const location = useLocation()

  const {
    holdingMoney,
    holdingMemberPoint,
    memberTotalGameMoney,
    holdemMoneyAmount,
    userId,
    memberLevel,
    noteRedisSize,
    oneToOneRedisSize,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdemMoneyAmount: memberInfo.holdemMoneyAmount,
      holdingMoney: memberInfo.memberHoldingMoney,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
      holdingMemberPoint: memberInfo.memberHoldingPoint,
      userId: memberInfo.userId,
      memberLevel: memberInfo.depositLevel,
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const {
    totalBettingPer,
    bettingCartList,
    bettingCartAlertMessage,
    bettingCartUpdateMessage,
    bettingCartCountExcludeBonus,
    bettingCartCount,
    selectedBonusFolder,
    activeSportsMarketType,
    sportsBettingInfo,
    bettingDelay,
  } = useSelector(state => {
    const { sportsBetting } = state

    const tempSelectedBonusFolder = sportsBetting.selectedBonusFolder

    let tempBettingCartCount

    if (Object.keys(tempSelectedBonusFolder).length === 0) {
      tempBettingCartCount = sportsBetting.bettingCartList.length
    } else {
      tempBettingCartCount = sportsBetting.bettingCartList.length + 1
    }

    return {
      totalBettingPer: sportsBetting.totalBettingPer,
      bettingCartList: sportsBetting.bettingCartList,
      bettingCartAlertMessage: sportsBetting.bettingCartAlertMessage,
      bettingCartUpdateMessage: sportsBetting.bettingCartUpdateMessage,
      bettingCartCountExcludeBonus: sportsBetting.bettingCartList.length,
      bettingCartCount: tempBettingCartCount,
      selectedBonusFolder: sportsBetting.selectedBonusFolder,
      activeSportsMarketType: sportsBetting.activeSportsMarketType,
      sportsBettingInfo: sportsBetting.sportsBettingInfos[sportsBetting.activeSportsMarketType],
      bettingDelay: sportsBetting.bettingDelay,
    }
  })

  const [realSportsBettingInfo, setRealSportsBettingInfo] = useState({})

  useEffect(() => {
    let sportsMaxBettingAmount = 0
    let sportsMaxWinningAmount = 0
    let sportsMaxBettingPer = 0
    let sportsMinBettingAmount = 0
    const sportsMinFolder = sportsBettingInfo?.sportsMinFolder || 0
    const sportsMaxFolder = sportsBettingInfo?.sportsMaxFolder || 0

    if (bettingCartCountExcludeBonus <= 1) {
      sportsMaxBettingAmount = sportsBettingInfo?.singleFolderSportsMaxBettingAmount || 0
      sportsMaxWinningAmount = sportsBettingInfo?.singleFolderSportsMaxWinningAmount || 0
      sportsMaxBettingPer = sportsBettingInfo?.singleFolderSportsMaxBettingPer || 0
      sportsMinBettingAmount = sportsBettingInfo?.singleFolderSportsMinBettingAmount || 0
    } else if (bettingCartCountExcludeBonus === 2) {
      sportsMaxBettingAmount = sportsBettingInfo?.twoFolderSportsMaxBettingAmount || 0
      sportsMaxWinningAmount = sportsBettingInfo?.twoFolderSportsMaxWinningAmount || 0
      sportsMaxBettingPer = sportsBettingInfo?.twoFolderSportsMaxBettingPer || 0
      sportsMinBettingAmount = sportsBettingInfo?.twoFolderSportsMinBettingAmount || 0
    } else {
      sportsMaxBettingAmount = sportsBettingInfo?.multiFolderSportsMaxBettingAmount || 0
      sportsMaxWinningAmount = sportsBettingInfo?.multiFolderSportsMaxWinningAmount || 0
      sportsMaxBettingPer = sportsBettingInfo?.multiFolderSportsMaxBettingPer || 0
      sportsMinBettingAmount = sportsBettingInfo?.multiFolderSportsMinBettingAmount || 0
    }

    setRealSportsBettingInfo({
      sportsMaxBettingAmount,
      sportsMaxWinningAmount,
      sportsMaxBettingPer,
      sportsMinBettingAmount,
      sportsMinFolder,
      sportsMaxFolder,
    })
  }, [bettingCartCountExcludeBonus, sportsBettingInfo])

  // betting cart 요소 변경시
  useEffect(() => {
    switch (activeSportsMarketType) {
      case SportMarketType.DOMESTIC:
        dispatch(setDomesticActiveBetInfo(bettingCartList))
        break
      case SportMarketType.OVERSEAS:
        dispatch(setOverseasActiveBetInfo(bettingCartList))
        break
      case SportMarketType.SPECIAL:
        dispatch(setSpecialActiveBetInfo(bettingCartList))
        break
      case SportMarketType.LIVE:
        dispatch(setLiveActiveBetInfo(bettingCartList))
        break
      default:
        dispatch(setDomesticActiveBetInfo(bettingCartList))
    }
  }, [activeSportsMarketType, bettingCartList, dispatch])

  const [inputBettingMoney, setInputBettingMoney] = useState('0')
  const [expectMoney, setExpectMoney] = useState(0)
  const [apiFlag, setApiFlag] = useState(false)

  useEffect(() => {
    const inputRealMoney = Number(inputBettingMoney?.toString().replaceAll(',', '')) || 0 // 입력된 배팅머니

    let resultBettingMoney = inputRealMoney
    let resultExpectMoney = Math.round(resultBettingMoney * totalBettingPer)

    const sportsMaxBettingAmount = realSportsBettingInfo?.sportsMaxBettingAmount || 0
    const sportsMaxWinningAmount = realSportsBettingInfo?.sportsMaxWinningAmount || 0

    if (resultBettingMoney > sportsMaxBettingAmount) {
      // 입력된 배팅머니가, 최대 배팅머니 보다 클 때
      resultBettingMoney = sportsMaxBettingAmount
      // 당첨금액이 최대 당첨 금액보다 큰 경우 => 300000 320000 -> 299999 배팅머니를 바꿔줘야해, 배팅비율은 고정이고
      if (resultExpectMoney > sportsMaxWinningAmount) {
        resultBettingMoney = Math.floor(sportsMaxWinningAmount / totalBettingPer) // 비율에 따른 최대 배팅금액
        resultExpectMoney = Math.round(resultBettingMoney * totalBettingPer)
      }
    }
    if (resultExpectMoney > sportsMaxWinningAmount) {
      // 입력된 배팅머니 * 비율이 최대 금액을 넘어 설때,
      resultBettingMoney = Math.floor(sportsMaxWinningAmount / totalBettingPer) // 비율에 따른 최대 배팅금액
      resultExpectMoney = Math.round(resultBettingMoney * totalBettingPer)
    }

    // 최종 결과 값이 최대 허용 금액을 초과하지 않도록 보정
    if (resultExpectMoney > sportsMaxWinningAmount) {
      resultExpectMoney = sportsMaxWinningAmount
    }

    setInputBettingMoney(resultBettingMoney.toString().replace(commonReg2, ','))
    setExpectMoney(resultExpectMoney.toString().replace(commonReg2, ','))
  }, [inputBettingMoney, totalBettingPer])

  const onClickMoneyHandler = amountText => {
    const textNum = amountText || holdingMoney

    setInputBettingMoney(prev => {
      const prevprevValue = prev ? prev.toString().replaceAll(',', '') : ''
      const prevValue = prevprevValue.startsWith('0') ? prevprevValue.slice(1) : prevprevValue
      if ((prevValue === '' || prevValue === '0') && (textNum === '0' || textNum === '00' || textNum === '000')) {
        return prevValue?.toString().replace(commonReg2, ',')
      }

      if (textNum.length > 3) {
        // UI키패드 버튼으로 5만 ~ 200만 까지 눌렀을때
        if (prevValue === '' || prevValue === '0') {
          return textNum?.toString().replace(commonReg2, ',')
        }
        // UI키패드 버튼으로 0,00,000 , 1~9까지 눌렀을때
        return (Number(prevValue) + Number(textNum))?.toString().replace(commonReg2, ',')
      }
      return (prevValue + textNum)?.toString().replace(commonReg2, ',')
    })
  }

  // 정정 버튼 클릭 핸들러
  const onClickResetMoneyHandler = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)
  }

  // 하나 삭제
  const onCloseSportsBettingCart = bettingCartItem => {
    dispatch(removeBettingCartItem(bettingCartItem))
  }
  // 전체 삭제
  const onClearSportsBettingCart = () => {
    setExpectMoney(0)
    setInputBettingMoney(0)
    dispatch(removeAllBettingCartItem())
  }

  // 배팅 딜레이
  const timeoutRef = useRef(null)

  useEffect(() => {
    return () => {
      // Clean up the timeout when the component unmounts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
      dispatch(setBettingDelay(false))
      dispatch(setBettingCartLoading(false))
      setApiFlag(false)
    }
  }, [bettingCartAlertMessage, bettingCartUpdateMessage])

  const onSubmit = e => {
    if (apiFlag) return
    setApiFlag(true)

    if (bettingCartList.length === 0) {
      alert('배팅할 마켓을 먼저 선택해주세요.')
      setApiFlag(false)
      return
    }

    const resultLock = bettingCartList.some(
      item =>
        !item.sportVisible ||
        !item.locationVisible ||
        !item.leagueVisible ||
        !item.fixtureVisible ||
        !item.homeVisible ||
        !item.awayVisible ||
        !item.usedMarket ||
        !item.marketVisible ||
        !item.selectedBetInfo.showStatus ||
        item.selectedBetInfo.betStatus !== 'OPEN',
    )

    if (resultLock) {
      alert('배팅 불가인 옵션이 있습니다.')
      setApiFlag(false)
      return
    }

    const sportsMinFolder = realSportsBettingInfo?.sportsMinFolder || 0

    if (bettingCartList.length < sportsMinFolder) {
      alert(`최소 ${sportsMinFolder}폴더부터 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    const sportsMaxFolder = realSportsBettingInfo?.sportsMaxFolder || 0

    if (bettingCartList.length > sportsMaxFolder) {
      alert(`최대 ${sportsMaxFolder}폴더까지 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    const sportsMaxBettingPer = realSportsBettingInfo?.sportsMaxBettingPer || 0

    if (totalBettingPer > sportsMaxBettingPer) {
      alert(`최대 ${sportsMaxBettingPer}배당까지 배팅이 가능합니다.`)
      setApiFlag(false)
      return
    }

    if (Number(holdingMoney) < Number(inputBettingMoney?.toString().replaceAll(',', ''))) {
      alert('지갑머니가 부족합니다.')
      setApiFlag(false)
      return
    }

    const sportsMinBettingAmount = realSportsBettingInfo?.sportsMinBettingAmount || 0

    if (Number(inputBettingMoney?.toString().replaceAll(',', '')) < Number(sportsMinBettingAmount)) {
      alert('최소 배팅금액 이상으로 가능 합니다.')
      setApiFlag(false)
      return
    }

    const sportsMaxBettingAmount = realSportsBettingInfo?.sportsMaxBettingAmount || 0

    if (Number(inputBettingMoney) > Number(sportsMaxBettingAmount)) {
      alert('최대 배팅금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    const sportsMaxWinningAmount = realSportsBettingInfo?.sportsMaxWinningAmount || 0

    if (Number(expectMoney?.toString().replaceAll(',', '')) > Number(sportsMaxWinningAmount)) {
      alert('최대 당첨금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (window.confirm('배팅하시겠습니까?')) {
      const body = {
        bettingAmount: Number(inputBettingMoney?.toString().replaceAll(',', '')),
        sportMarketType: bettingCartList[0].sportMarketType,
        sportsDetailBettingKeyList: bettingCartList.map(cartItem => {
          return {
            fixtureId: cartItem.fixtureId,
            eventBetKeyList: cartItem.bets.map(betItem => betItem.eventBetKey),
            selectedBetKey: cartItem.selectedBetInfo.eventBetKey,
          }
        }),
        bonusFolder: Object.keys(selectedBonusFolder).length === 0 ? null : selectedBonusFolder,
      }

      if (sportsBettingInfo.bettingDelayTime > 0) {
        dispatch(setBettingDelay(true))
      }

      timeoutRef.current = setTimeout(() => {
        dispatch(setBettingDelay(false))
        dispatch(setBettingCartLoading(true))
        // API 호출
        bettingSportsEventV2(body)
          .then(res => {
            alert('배팅 성공했습니다.')
          })
          .catch(error => {
            const errorCode = error?.response?.data?.errorCode ?? ''

            switch (errorCode) {
              case 'SPORTS-1010':
                alert('마감된 경기가 있어 거절됩니다.')
                break
              case 'SYSTEM-1017':
                alert('보유한 머니가 충분하지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1031':
                alert('다폴 배팅허용기준에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1036':
                alert('최소 배당 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1037':
                alert('최대 배당 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1038':
                alert('최소 배팅액 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1039':
                alert('최대 배팅액 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1040':
                alert('최대 당첨액 조건에 부합되지 않아 배팅이 거절됩니다.')
                break
              case 'SPORTS-1041':
                alert('단폴 배팅이 허용되지 않은 마켓입니다.')
                break
              case 'SPORTS-1042':
                alert('배팅 대기시간으로 인해 배팅이 거절됩니다.')
                break
              case 'SPORTS-1045':
                alert('배당 변경으로 인해 배팅이 거절됩니다.')
                break
              default:
                alert('배팅 실패했습니다. 잠시후 다시 시도해주세요.')
            }

            dispatch(setBettingCartLoading(false))
            setApiFlag(false)
          })
          .finally(() => {
            dispatch(setBettingCartLoading(false))
            setApiFlag(false)
            onClickResetMoneyHandler()
            dispatch(removeAllBettingCartItem())
            dispatch(fetchMemberInfoAction())
          })
      }, (sportsBettingInfo?.bettingDelayTime ?? 0) * 1000)
    } else {
      dispatch(setBettingCartLoading(false))
      setApiFlag(false)
    }
  }

  useEffect(() => {
    // 다른페이지 배팅카트 초기화
    onClearSportsBettingCart()

    const { pathname } = location

    switch (pathname) {
      case '/sports/overseas':
        dispatch(setActiveSportsMarketType(SportMarketType.OVERSEAS))
        break
      case '/sports/special':
        dispatch(setActiveSportsMarketType(SportMarketType.SPECIAL))
        break
      case '/sports/live':
        dispatch(setActiveSportsMarketType(SportMarketType.LIVE))
        break
      case '/sports/domestic':
      default:
        dispatch(setActiveSportsMarketType(SportMarketType.DOMESTIC))
        break
    }
  }, [location])

  const onClickCloseHandler = () => {
    dispatch(onClickBettingCartAlertCloseHandler())
  }

  const onClickBettingCartUpdateCloseHandler = () => {
    dispatch(onClickBettingCartUpdateMessageCloseHandler())
  }

  const getLevelIconUrl = () => {
    switch (true) {
      case memberLevel === 'ONE':
        return `${process.env.PUBLIC_URL}/img/level/level1.png`
      case memberLevel === 'TWO':
        return `${process.env.PUBLIC_URL}/img/level/level2.png`
      case memberLevel === 'THREE':
        return `${process.env.PUBLIC_URL}/img/level/level3.png`
      case memberLevel === 'FOUR':
        return `${process.env.PUBLIC_URL}/img/level/level4.png`
      case memberLevel === 'FIVE':
        return `${process.env.PUBLIC_URL}/img/level/level5.png`
      case memberLevel === 'VIP1':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP1.png`
      case memberLevel === 'VIP2':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP2.png`
      case memberLevel === 'VIP3':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP3.png`
      default:
        return ''
    }
  }

  const onClickSignOutHandler = () => {
    handleDeleteAuthentication({
      accessToken: getAccessToken(),
    }).then(res => {
      deleteAccessToken()
      window.location.href = '/'
    })
  }

  const [showBettingCart, setShowBettingCart] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  const [isValidPath, setIsValidPath] = useState(false)

  const [isMainPage, setIsMainPage] = useState(false)

  useEffect(() => {
    if (
      location.pathname === '/sports/domestic' ||
      location.pathname === '/sports/overseas' ||
      location.pathname === '/sports/special' ||
      location.pathname === '/sports/live' ||
      location.pathname === '/sportsgame' ||
      location.pathname === '/special-sports' ||
      location.pathname === '/'
    ) {
      setIsValidPath(true)
      if (location.pathname === '/') {
        setIsMainPage(true)
      } else {
        setIsMainPage(false)
      }
    } else {
      setIsValidPath(false)
      setIsMainPage(false)
    }
  }, [location])

  useEffect(() => {
    const handleResize = () => {
      const resultMatches = window.matchMedia('(max-width: 1440px)').matches
      if (resultMatches) {
        setIsSmallScreen(true)
      } else {
        setIsSmallScreen(false)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const toggleIconCart = () => {
    setShowBettingCart(prevShowBettingCart => !prevShowBettingCart)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const onClickMenuHandler = title => {
    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }

  const [alertPopupShow, setAlertPopupShow] = useState(false)
  // 경고창 용 팝업
  const [alertPopupMessage, setAlertPopupMessage] = useState('')

  const onCloseAlertPopup = () => {
    setAlertPopupShow(false)
    setAlertPopupMessage('')
  }

  const onChangeAlertPopupMessageHandler = text => {
    setAlertPopupShow(true)
    setAlertPopupMessage(text)
  }

  const [moneyExchangeLoading, setMoneyExchangeLoading] = useState(false)

  const onClickCasinoMoneyWithdrawHandler = () => {
    if (moneyExchangeLoading) {
      onChangeAlertPopupMessageHandler('카지노 머니 회수 진행중입니다. 잠시만 기다려주세요.')
      return
    }
    if (memberTotalGameMoney <= 0) return

    setMoneyExchangeLoading(true)

    withdrawCasinoMoney()
      .then(res => {
        dispatch(fetchMemberInfoAction())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'GAME-1014':
            onChangeAlertPopupMessageHandler('카지노 머니 동기화중입니다. \n잠시후에 다시 시도해주세요.')
            break
          case 'ASSET-7001':
            onChangeAlertPopupMessageHandler('잠시후 다시 시도해주세요.')
            break
          default:
            onChangeAlertPopupMessageHandler('처리 실패했습니다.')
        }
      })
      .finally(() => {
        setMoneyExchangeLoading(false)
      })
  }

  const getBettingInfoCompo = folderSize => {
    if (folderSize <= 1) {
      return (
        <BettingTotalInfoWrap>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>총 배당:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>{totalBettingPer || 0} 배</span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>총 배당금액:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>{expectMoney?.toString().replace(commonReg2, ',') || 0} 원</span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>단폴 최소 배팅금액:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>
                    {realSportsBettingInfo?.sportsMinBettingAmount?.toString().replace(commonReg2, ',') || 0} 원
                  </span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>단폴 최대 배팅금액:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>
                    {realSportsBettingInfo?.sportsMaxBettingAmount?.toString().replace(commonReg2, ',') || 0} 원
                  </span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>단폴 최대 당첨금액:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>
                    {realSportsBettingInfo?.sportsMaxWinningAmount?.toString().replace(commonReg2, ',') || 0} 원
                  </span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>단폴 최대 배팅배당:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>{realSportsBettingInfo?.sportsMaxBettingPer?.toString().replace(commonReg2, ',') || 0} 배</span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
        </BettingTotalInfoWrap>
      )
    }
    if (folderSize === 2) {
      return (
        <BettingTotalInfoWrap>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>총 배당:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>{totalBettingPer || 0} 배</span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>총 배당금액:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>{expectMoney?.toString().replace(commonReg2, ',') || 0} 원</span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>두폴 최소 배팅금액:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>
                    {realSportsBettingInfo?.sportsMinBettingAmount?.toString().replace(commonReg2, ',') || 0} 원
                  </span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>두폴 최대 배팅금액:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>
                    {realSportsBettingInfo?.sportsMaxBettingAmount?.toString().replace(commonReg2, ',') || 0} 원
                  </span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>두폴 최대 당첨금액:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>
                    {realSportsBettingInfo?.sportsMaxWinningAmount?.toString().replace(commonReg2, ',') || 0} 원
                  </span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
          <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
            <tbody>
              <tr>
                <BettingTotalInfoFirstTd>
                  <span>두폴 최대 배팅배당:</span>
                </BettingTotalInfoFirstTd>
                <BettingTotalInfoSecondTd>
                  <span>{realSportsBettingInfo?.sportsMaxBettingPer?.toString().replace(commonReg2, ',') || 0} 배</span>
                </BettingTotalInfoSecondTd>
              </tr>
            </tbody>
          </table>
        </BettingTotalInfoWrap>
      )
    }
    return (
      <BettingTotalInfoWrap>
        <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
          <tbody>
            <tr>
              <BettingTotalInfoFirstTd>
                <span>총 배당:</span>
              </BettingTotalInfoFirstTd>
              <BettingTotalInfoSecondTd>
                <span>{totalBettingPer || 0} 배</span>
              </BettingTotalInfoSecondTd>
            </tr>
          </tbody>
        </table>
        <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
          <tbody>
            <tr>
              <BettingTotalInfoFirstTd>
                <span>총 배당금액:</span>
              </BettingTotalInfoFirstTd>
              <BettingTotalInfoSecondTd>
                <span>{expectMoney?.toString().replace(commonReg2, ',') || 0} 원</span>
              </BettingTotalInfoSecondTd>
            </tr>
          </tbody>
        </table>
        <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
          <tbody>
            <tr>
              <BettingTotalInfoFirstTd>
                <span>다폴 최소 배팅금액:</span>
              </BettingTotalInfoFirstTd>
              <BettingTotalInfoSecondTd>
                <span>
                  {realSportsBettingInfo?.sportsMinBettingAmount?.toString().replace(commonReg2, ',') || 0} 원
                </span>
              </BettingTotalInfoSecondTd>
            </tr>
          </tbody>
        </table>
        <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
          <tbody>
            <tr>
              <BettingTotalInfoFirstTd>
                <span>다폴 최대 배팅금액:</span>
              </BettingTotalInfoFirstTd>
              <BettingTotalInfoSecondTd>
                <span>
                  {realSportsBettingInfo?.sportsMaxBettingAmount?.toString().replace(commonReg2, ',') || 0} 원
                </span>
              </BettingTotalInfoSecondTd>
            </tr>
          </tbody>
        </table>
        <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
          <tbody>
            <tr>
              <BettingTotalInfoFirstTd>
                <span>다폴 최대 당첨금액:</span>
              </BettingTotalInfoFirstTd>
              <BettingTotalInfoSecondTd>
                <span>
                  {realSportsBettingInfo?.sportsMaxWinningAmount?.toString().replace(commonReg2, ',') || 0} 원
                </span>
              </BettingTotalInfoSecondTd>
            </tr>
          </tbody>
        </table>
        <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
          <tbody>
            <tr>
              <BettingTotalInfoFirstTd>
                <span>다폴 최대 배팅배당:</span>
              </BettingTotalInfoFirstTd>
              <BettingTotalInfoSecondTd>
                <span>{realSportsBettingInfo?.sportsMaxBettingPer?.toString().replace(commonReg2, ',') || 0} 배</span>
              </BettingTotalInfoSecondTd>
            </tr>
          </tbody>
        </table>
      </BettingTotalInfoWrap>
    )
  }

  const getPriceBox = item => {
    // 정규식 패턴: "0-0" 형태 검사
    const pattern = /^(\d+)-(\d+)$/
    const match = item.selectedBetInfo?.baseLineScore?.match(pattern)

    // 핸디 일때만
    if (item.marketFamilyType === 11 && match) {
      const homeScore = Number.isNaN(Number(match[1])) ? 0 : Number(match[1])
      const awayScore = Number.isNaN(Number(match[2])) ? 0 : Number(match[2])

      // 공식 : 베이스라인 기준점 - 홈 스코어 + 어웨이 스코어
      let finalLine = item.selectedBetInfo.baseLineNum - homeScore + awayScore

      // 원정팀 선택시
      if (item.selectedBetInfo.betName === '2') {
        finalLine *= -1
      }

      return (
        <>
          {item.selectedBetInfo?.line ? (
            <span>
              {item.marketName}({finalLine.toFixed(1)}) - {item.selectedBetInfo.betNameKr}
            </span>
          ) : (
            <span>
              {item.marketName} - {item.selectedBetInfo.betNameKr}
            </span>
          )}
        </>
      )
    }

    // e-sports 특정 라운드 승자 (마켓)
    if (item.marketFamilyType === 5) {
      return (
        <>
          {item.selectedBetInfo?.line ? (
            <span>
              {item.marketName}({item.selectedBetInfo?.line}) - {item.selectedBetInfo.betNameKr}
            </span>
          ) : (
            <span>
              {item.marketName} - {item.selectedBetInfo.betNameKr}
            </span>
          )}
        </>
      )
    }

    return (
      <>
        {item.selectedBetInfo?.line ? (
          <span>
            {item.marketName}({item.selectedBetInfo.lineNum.toFixed(1)}) - {item.selectedBetInfo.betNameKr}
          </span>
        ) : (
          <span>
            {item.marketName} - {item.selectedBetInfo.betNameKr}
          </span>
        )}
      </>
    )
  }

  const onClickMenuModalHandler = title => {
    if (!isLogin()) {
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }

  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}

      {alertPopupShow && <CustomAlert info={alertPopupMessage} onClickCloseHandler={onCloseAlertPopup} />}

      {isValidPath && isSmallScreen && !showBettingCart && (
        <SporteBettingCartWrap onClick={toggleIconCart} isMainPage={isMainPage}>
          {isMainPage && <img src={`${process.env.PUBLIC_URL}/img/main/bettingCart3.png`} alt="" />}
          {bettingCartCount > 0 && (
            <>
              <img src={`${process.env.PUBLIC_URL}/img/main/bettingCart3_num.png`} alt="" />
              <span>{bettingCartCount} </span>
            </>
          )}
        </SporteBettingCartWrap>
      )}
      {bettingDelay && <CustomBettingAlert bettingDelayTime={sportsBettingInfo?.bettingDelayTime ?? 0} />}

      {bettingCartAlertMessage && (
        <CustomAlert info={bettingCartAlertMessage} onClickCloseHandler={onClickCloseHandler} />
      )}
      {bettingCartUpdateMessage && (
        <CustomAlert info={bettingCartUpdateMessage} onClickCloseHandler={onClickBettingCartUpdateCloseHandler} />
      )}

      {isValidPath && isSmallScreen && showBettingCart && (
        <BettingCartCloseWrap showBettingCart={showBettingCart} onClick={toggleIconCart} />
      )}

      <SidbarWrap showBettingCart={showBettingCart || !isSmallScreen}>
        <SlimScrollDiv>
          <SidbarMenuWrap className="sidebar-container">
            {loginCheck && (
              <LoginInfoBox isVisible={loginCheck}>
                <LoginInfoBoxHeader>My Profile</LoginInfoBoxHeader>
                <LoginInfoBoxContentWrap>
                  <LoginInfoBoxContentFirstBox>
                    <LoginInfoBoxContentFirstLevel $url={getLevelIconUrl()} />
                    <LoginInfoBoxContentFirstUserId>{userId}</LoginInfoBoxContentFirstUserId>
                    <LoginInfoBoxContentFirstNickName>
                      {decodeAccessToken()?.nickName || ''}
                    </LoginInfoBoxContentFirstNickName>
                  </LoginInfoBoxContentFirstBox>
                  <LoginInfoBoxContentSecondBox>
                    <div style={{ width: '50%' }}>
                      <LoginInfoBoxContentSecondItem>스포츠캐쉬</LoginInfoBoxContentSecondItem>
                      <div>{holdingMoney?.toString().replace(commonReg2, ',')}원</div>
                    </div>
                    <div
                      style={{
                        width: '50%',
                        display: 'flex',
                        textAlign: 'right',
                        gap: '0px',
                        marginLeft: 'auto',
                        justifyContent: 'end',
                      }}
                    >
                      <CasinoMoneyWithdrawBox onClick={onClickCasinoMoneyWithdrawHandler}>
                        <CustomHiOutlineRefresh />
                        <span>회수</span>
                      </CasinoMoneyWithdrawBox>
                      <div>
                        <LoginInfoBoxContentSecondItem>카지노캐쉬</LoginInfoBoxContentSecondItem>
                        <div>{memberTotalGameMoney?.toString().replace(commonReg2, ',')}원</div>
                      </div>
                    </div>
                  </LoginInfoBoxContentSecondBox>
                  <LoginInfoBoxContentSecondBox>
                    <div
                      style={{
                        width: '50%',
                        display: 'flex',
                        textAlign: 'left',
                        gap: '0px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onClickMenuModalHandler('POINT_EXCHANGE')
                      }}
                    >
                      <LoginInfoBoxContentSecondItem>
                        보유포인트{' '}
                        <div style={{ color: '#fff' }}>{holdingMemberPoint?.toString().replace(commonReg2, ',')}원</div>
                      </LoginInfoBoxContentSecondItem>
                      <CasinoMoneyWithdrawBox style={{ marginLeft: '3px' }}>
                        <CustomHiOutlineRefresh />
                        <span>전환</span>
                      </CasinoMoneyWithdrawBox>
                    </div>
                    <div
                      style={{
                        width: '50%',
                        display: 'flex',
                        textAlign: 'right',
                        gap: '0px',
                        cursor: 'pointer',
                        justifyContent: 'right',
                      }}
                    >
                      <LoginInfoBoxContentSecondItem>
                        홀덤머니{' '}
                        <div style={{ color: '#fff' }}>{holdemMoneyAmount?.toString().replace(commonReg2, ',')}원</div>
                      </LoginInfoBoxContentSecondItem>
                    </div>
                  </LoginInfoBoxContentSecondBox>
                  <LoginInfoBoxContentThirdBox>
                    <LoginInfoBoxContentThirdItemBox
                      onClick={() => {
                        onClickMenuHandler('DEPOSIT')
                      }}
                    >
                      <LoginInfoBoxContentThirdItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-deposit.png`} alt="" />
                      </LoginInfoBoxContentThirdItemIcon>
                      <LoginInfoBoxContentThirdItemText>입금</LoginInfoBoxContentThirdItemText>
                    </LoginInfoBoxContentThirdItemBox>
                    <LoginInfoBoxContentThirdItemBox
                      onClick={() => {
                        onClickMenuHandler('WITHDRAWAL')
                      }}
                    >
                      <LoginInfoBoxContentThirdItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-withdrawal.png`} alt="" />
                      </LoginInfoBoxContentThirdItemIcon>
                      <LoginInfoBoxContentThirdItemText>출금</LoginInfoBoxContentThirdItemText>
                    </LoginInfoBoxContentThirdItemBox>
                    <LoginInfoBoxContentThirdItemBox
                      onClick={() => {
                        onClickMenuHandler('NOTE')
                      }}
                    >
                      <LoginInfoBoxContentThirdItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-note.png`} alt="" />
                      </LoginInfoBoxContentThirdItemIcon>
                      <LoginInfoBoxContentThirdItemText>쪽지</LoginInfoBoxContentThirdItemText>
                    </LoginInfoBoxContentThirdItemBox>
                    <LoginInfoBoxContentThirdItemBox
                      onClick={() => {
                        onClickMenuHandler('ONETOONE')
                      }}
                    >
                      <LoginInfoBoxContentThirdItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-onetoone.png`} alt="" />
                      </LoginInfoBoxContentThirdItemIcon>
                      <LoginInfoBoxContentThirdItemText>문의</LoginInfoBoxContentThirdItemText>
                    </LoginInfoBoxContentThirdItemBox>
                    <LoginInfoBoxContentThirdItemLogout onClick={onClickSignOutHandler}>
                      <img src={`${process.env.PUBLIC_URL}/img/main/logout.png`} alt="" />
                    </LoginInfoBoxContentThirdItemLogout>
                  </LoginInfoBoxContentThirdBox>
                </LoginInfoBoxContentWrap>
              </LoginInfoBox>
            )}

            <BettingCartBox>
              <BettingCartHeaderBox>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {bettingCartCount > 0 && <BettingFolderNum>{bettingCartCount}</BettingFolderNum>}
                        <strong style={{ marginTop: '5px', fontWeight: '700', fontSize: '16px' }}>BET</strong>
                      </td>
                      <td style={{ width: '16.66666%', padding: '7px' }} />
                      <td style={{ fontSize: '14px' }}>
                        <BettingDeleteAll onClick={onClearSportsBettingCart}>Clear all</BettingDeleteAll>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </BettingCartHeaderBox>

              <BettingListWrap>
                {bettingCartCount >= 0 ? (
                  <>
                    {bettingCartList.map(item => {
                      const isLock =
                        !item.sportVisible ||
                        !item.locationVisible ||
                        !item.leagueVisible ||
                        !item.fixtureVisible ||
                        !item.homeVisible ||
                        !item.awayVisible ||
                        !item.usedMarket ||
                        !item.marketVisible ||
                        !item.selectedBetInfo.showStatus ||
                        item.selectedBetInfo.betStatus !== 'OPEN'

                      return (
                        <>
                          <BettingListInnerWrap>
                            {isLock && (
                              <BettingWhatWrapOverlay>
                                <BettingWhatLockIcon />
                              </BettingWhatWrapOverlay>
                            )}
                            <BettingListItemLeagueBox>{item.leagueName}</BettingListItemLeagueBox>
                            <BettingListItemTeamBox>
                              {item.homeName && `${item.homeName} vs ${item.awayName}`}
                            </BettingListItemTeamBox>

                            <BettingListItemMarketBox>{getPriceBox(item)}</BettingListItemMarketBox>
                            <BettingListItemDeleteBox
                              onClick={() => {
                                onCloseSportsBettingCart(item)
                              }}
                            >
                              <CustomIoClose />
                            </BettingListItemDeleteBox>
                            <BettingListItemPointBox>
                              <div>
                                <div>{item.selectedBetInfo.priceNum}</div>
                              </div>
                            </BettingListItemPointBox>
                          </BettingListInnerWrap>
                        </>
                      )
                    })}
                    {Object.keys(selectedBonusFolder).length !== 0 && (
                      <BettingListInnerWrap>
                        <BettingListItemLeagueBox />
                        <BettingListItemTeamBox>보너스 배당</BettingListItemTeamBox>

                        <BettingListItemMarketBox>
                          <span>{selectedBonusFolder.bonusFolderNum}폴더 이상</span>
                        </BettingListItemMarketBox>
                        <BettingListItemDeleteBox
                          onClick={() => {
                            dispatch(setSelectedBonusFolder({}))
                          }}
                        >
                          <CustomIoClose />
                        </BettingListItemDeleteBox>
                        <BettingListItemPointBox>
                          <div>
                            <div>{selectedBonusFolder.bonusFolderPer}</div>
                          </div>
                        </BettingListItemPointBox>
                      </BettingListInnerWrap>
                    )}
                  </>
                ) : (
                  <BettingListInnerWrap>
                    <BettingListBox>
                      <BettingListEmptyBox>
                        <div>
                          <img
                            src={`${process.env.PUBLIC_URL}/img/sidebar/side_bets_empty.png`}
                            alt=""
                            style={{ marginBottom: '16px' }}
                          />
                        </div>
                        선택된 배팅내역이 없습니다.
                      </BettingListEmptyBox>
                    </BettingListBox>
                  </BettingListInnerWrap>
                )}
              </BettingListWrap>

              <Form
                // ref={formRef}
                onSubmit={onSubmit}
                initialValues={{
                  totalBettingPer,
                  inputBettingMoney,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <BettingAssetFormWrap onSubmit={handleSubmit}>
                    <BettingInputWrap>
                      <BettingInputInlineBlockWrap>
                        <BettingInputBox>
                          <BettingInputBoxLeftBox>
                            <BettingInputBoxLeftItem>배팅</BettingInputBoxLeftItem>
                            <BettingInputBoxLeftItemRefresh onClick={onClickResetMoneyHandler}>
                              <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-refresh.png`} alt="" />
                            </BettingInputBoxLeftItemRefresh>
                          </BettingInputBoxLeftBox>
                          <BettingInputBoxRightBox>
                            <Field
                              name="inputBettingMoney"
                              component={BettingCartFormField}
                              isAboveError={false}
                              wrapperClassName="betting-amount-input"
                            >
                              {({ input }) => (
                                <input
                                  type="text"
                                  name="inputBettingMoney"
                                  value={inputBettingMoney}
                                  placeholder={inputBettingMoney}
                                  onChange={e => {
                                    const value = e.target.value.trim()
                                    const numbersOnly = value.match(/\d+/g)?.join('')

                                    if (!numbersOnly) {
                                      setInputBettingMoney('0')
                                    } else {
                                      setInputBettingMoney(numbersOnly.replace(/^0+/, '').replace(commonReg2, ','))
                                    }

                                    input.onChange(e)
                                  }}
                                />
                              )}
                            </Field>
                          </BettingInputBoxRightBox>
                        </BettingInputBox>
                      </BettingInputInlineBlockWrap>
                    </BettingInputWrap>

                    <BettingButtonWrap>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ padding: '4px', paddingLeft: '0', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('5000')}>
                                오천
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('10000')}>
                                일만
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('50000')}>
                                오만
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('100000')}>
                                십만
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('')}>
                                전액
                              </BettingButton>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </BettingButtonWrap>

                    {getBettingInfoCompo(bettingCartCountExcludeBonus)}

                    <BettingBetButtonWrap>
                      <BettingBetButton type="submit">배팅하기</BettingBetButton>
                    </BettingBetButtonWrap>
                  </BettingAssetFormWrap>
                )}
              </Form>
            </BettingCartBox>

            <BettingBottomBox>
              <BettingBottomItem
                onClick={() => {
                  window.open('https://타이슨.com')
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/main/forever2.png`} alt="" style={{ width: '100%' }} />
              </BettingBottomItem>
              <BettingBottomItem
                onClick={() => {
                  window.open('https://t.me/tysonsol')
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/main/forever1.png`} alt="" style={{ width: '100%' }} />
              </BettingBottomItem>
              <BettingBottomItem
                onClick={() => {
                  window.open('https://t.me/tysonsol')
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/main/cart_logo.png`} alt="" style={{ width: '100%' }} />
              </BettingBottomItem>
            </BettingBottomBox>
          </SidbarMenuWrap>
        </SlimScrollDiv>
      </SidbarWrap>
    </>
  )
}

export default SportsBettingCartSidebar

const CasinoMoneyWithdrawBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: max-content;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
  &:hover {
    color: ${HermesMainText};
    span {
      color: ${HermesMainText};
    }
  }
  span {
    font-size: 13px;
    font-weight: 500;
    margin-top: 2px;
    color: #b2b2b2;
    &:hover {
      color: ${HermesMainText} !important;
    }
  }
`

// 카지노 머니 회수 아이콘
const CustomHiOutlineRefresh = styled(HiOutlineRefresh)`
  width: 17px !important;
  height: auto;
  cursor: pointer;
  color: ${HermesMainText} !important;
`

const BettingCartCloseWrap = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: transparent;
  ${props =>
    props.showBettingCart
      ? `
    display: block;
`
      : `display: none;`}
`

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%); /* 시작 지점을 오른쪽 끝으로 설정 */
    opacity: 0;
  }
  to {
    transform: translateX(0); /* 끝 지점을 오른쪽으로부터 이동 없음으로 설정 */
    opacity: 1;
  }
`

const SidbarWrap = styled.div`
  transition: all 0.5s ease-out 0s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  z-index: 1010;
  -webkit-transform: translateZ(0);
  margin-top: 65px;
  width: 299px;
  z-index: 1010;
  animation: ${slideInFromRight} 0.5s ease-out;

  ${props =>
    props.showBettingCart
      ? `
    display: block;
`
      : `display: none;`}
`

// 스크롤 용도
const SlimScrollDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  height: 100%;
`

const SidbarMenuWrap = styled.div`
  height: 100%;
  overflow: auto;
  margin-bottom: 30px;
  &::-webkit-scrollbar {
    width: 0; /* 스크롤 바 너비를 0으로 설정하여 숨김 */
  }
`

const LogInInfoBoxRight = keyframes`
  from {
    transform: translateX(100%); /* 시작 지점을 오른쪽 끝으로 설정 */
    opacity: 0;
  }
  to {
    transform: translateX(0); /* 끝 지점을 오른쪽으로부터 이동 없음으로 설정 */
    opacity: 1;
  }
`

const LoginInfoBox = styled.div`
  background: #1c1f21;
  border-radius: 6px;
  color: #fff;
  margin-bottom: 9px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  animation: ${LogInInfoBoxRight} 0.5s ease-out;
`

const LoginInfoBoxHeader = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-bottom: 10px;
  padding-right: 16px;
  padding-top: 10px;
`

const LoginInfoBoxContentWrap = styled.div`
  background: #1a1c1f;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
`

const LoginInfoBoxContentFirstBox = styled.div`
  margin-top: 16px;
  display: flex;
  algin-items: center;
  position: relative;
`

const LoginInfoBoxContentFirstLevel = styled.div`
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  // background-image: url(${process.env.PUBLIC_URL}/img/level/level1.png);
  background-image: url(${props => (props.$url ? `${props.$url}` : '')});
  margin-right: 20px;
`

const LoginInfoBoxContentFirstUserId = styled.div`
  display: flex;
  align-items: center;
`

const LoginInfoBoxContentFirstNickName = styled.div`
  color: ${HermesMainHover2};
  margin-left: auto;
  display: flex;
  align-items: center;
`

const LoginInfoBoxContentSecondBox = styled.div`
  margin-top: 15px;
  display: flex;
`

const LoginInfoBoxContentSecondItem = styled.div`
  color: #b3b3b3;
  margin-bottom: 5px;
`

const LoginInfoBoxContentThirdBox = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
`

const LoginInfoBoxContentThirdItemBox = styled.div`
  height: 48px;
  border-radius: 6px;
  cursor: pointer;
  float: left;
  transition: background-color 0.1s ease-out 0s;
  width: 22%;

  &:hover {
    cursor: pointer;
    background-color: #313134;
  }
`

const LoginInfoBoxContentThirdItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  cursor: pointer;
  img {
    cursor: pointer;
    width: 20px;
  }
`
const LoginInfoBoxContentThirdItemText = styled.div`
  text-align: center;
  cursor: pointer;
`

const LoginInfoBoxContentThirdItemLogout = styled.div`
  width: 12%;
  height: 48px;
  border-radius: 6px;
  cursor: pointer;
  float: left;
  transition: background-color 0.1s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #313134;
  }

  img {
    cursor: pointer;
  }
`

// 배팅 카트
const BettingCartBox = styled.div`
  background: #1a1c1f;
  border-radius: 6px;
  margin-top: 8px;
  padding: 0;
  margin-bottom: 9px;
`

const BettingCartHeaderBox = styled.div`
  padding: 12px;
  border: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  table {
    width: 100%;
    padding: 0;
    margin: 0;
    display: table;
    border-color: gray;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;

    tbody tr td {
      vertical-align: middle !important;
      color: #fff;
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }
`

// 폴더 수
const BettingFolderNum = styled.span`
  font-family: arial;
  border-radius: 50%;

  background: ${HermesMainBorder};
  color: #000;
  opacity: 0.9;
  cursor: pointer;
  width: 22px;
  height: 22px;
  font-weight: 700;
  text-align: center;
  display: inline-block;
  min-width: 10px;
  padding-top: 4px;
  margin-right: 4px;
`

const BettingDeleteAll = styled.div`
  float: right;
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.1s ease-out 0s;

  &:hover {
    color: ${HermesMainHover};
  }
`

const BettingListWrap = styled.div`
  box-sizing: border-box;
  padding: 0 8px;
`

const BettingListInnerWrap = styled.div`
  background-size: 100% 100%;
  background-image: url(${process.env.PUBLIC_URL}/img/sidebar/side-betlist.png);
  background-repeat: no-repeat;
  clear: both;
  text-align: left;
  margin: 1px 0 3px 1px;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-border-radius: 3px;
  width: 100%;
  height: 115px;
  position: relative;
`

const BettingListItemLeagueBox = styled.div`
  width: 210px;
  min-width: 210px;
  color: #848c99;
  margin-top: 16px;
  padding: 0 16px;
  overflow-x: hidden;
  float: left;
  font-family: 'Malgun Gothic', dotum;
  height: 17px;
  line-height: 17px;
`

const CustomIoClose = styled(IoClose)``

const BettingListItemDeleteBox = styled.div`
  color: #fff;
  font-size: 22px;
  padding: 2px 5px 5px;
  margin: 0;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  border-radius: 6px;
  float: right;
  font-family: 'Malgun Gothic', dotum;
  line-height: 17px;
  z-index: 333;
`

const BettingListItemTeamBox = styled.div`
  width: 210px;
  min-width: 210px;
  color: #fff;
  margin-top: 16px;
  padding: 0 16px;
  overflow-x: hidden;
  float: left;
  font-family: 'Malgun Gothic', dotum;
  height: 17px;
  line-height: 17px;
`
const BettingListItemMarketBox = styled.div`
  width: 190px;
  min-width: 190px;
  color: #fff;
  padding: 0;
  margin-top: 16px;
  padding: 0 16px;
  overflow-x: hidden;
  float: left;
  font-family: 'Malgun Gothic', dotum;
  height: 17px;
  line-height: 17px;
  font-weight: 700;
`

const BettingListItemPointBox = styled.div`
  float: right;
  font-family: 'Malgun Gothic', dotum;
  line-height: 17px;
  color: ${HermesMainHover2};
  font-weight: 700;
  height: 17px;
  border-radius: 3px;
  margin: 10px 6px 0 0;

  div {
    border-radius: 4px;
    display: inline-block;
    padding: 5px 6px;

    div {
      display: block;
      float: left;
    }
  }
`

const BettingListBox = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;
  clear: both;
  width: 100%;
  height: 115px;
  text-align: left;
  margin: 1px 0 3px 1px;
  overflow-x: hidden;
  overflow-y: hidden;
`

const BettingListEmptyBox = styled.div`
  width: auto;
  color: #fff;
  display: block;
  text-align: center;
  margin: 0;
`

const BettingInputWrap = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
`

const BettingInputInlineBlockWrap = styled.div`
  display: inline-block;
  width: 100%;
`

const BettingInputBox = styled.div`
  margin-top: 16px;
  background: rgb(31, 33, 36);
  display: flex;
  padding: 16px 16px 12px 16px;
`

const BettingInputBoxLeftBox = styled.div`
  background: rgb(37, 40, 43);
  border-radius: 6px;
  color: ${HermesMainText} !important;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  padding: 2px;
`

const BettingInputBoxLeftItem = styled.div`
  padding: 0 12px;
`
const BettingInputBoxLeftItemRefresh = styled.div`
  padding: 6px;
  background: #2e3236;
  border-radius: 6px;
  cursor: pointer;

  img {
    cursor: pointer;
  }
`

const BettingInputBoxRightBox = styled.div`
  flex: 1 1 0%;
  padding-left: 12px;

  input {
    font-weight: 700;
    color: #fff;
    width: 100%;
    height: 40px;
    background: #181b1c;
    border: 1px solid #738087;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    text-align: center;
    border-radius: 8px;
  }
`

const BettingButtonWrap = styled.div`
  background: rgb(31, 33, 36);
  padding: 0 16px;

  table {
    background: #1f2124 !important;
    padding: 0;
    margin: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;
    }

    tbody tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;
    }
  }
`

const BettingButton = styled.button`
  color: #fff;
  background: #25282b;

  border: none;
  box-shadow: none;
  transition: background-color 0.1s ease-out 0s, color 0.1s ease-out 0s;

  border-radius: 5px;
  width: 100%;
  padding: 8px;
  margin-right: 2px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;

  &:hover {
    background: #34383e;
  }
`

const BettingTotalInfoWrap = styled.div`
  background: #1f2124;
  padding: 0 16px;

  table {
    background: #1f2124;
    width: 100%;
    padding: 0;
    margin: 0;
  }
`

const BettingTotalInfoFirstTd = styled.td`
  width: 50%;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  padding: 8px;
  margin: 0;

  span {
    color: #fff;
    float: left;
  }
`

const BettingTotalInfoSecondTd = styled.td`
  width: 50%;
  text-align: center;
  padding: 0;
  margin: 0;

  span {
    color: ${HermesMainText};
    float: right;
  }
`

const BettingBetButtonWrap = styled.div`
  border-radius: 0px 0px 8px 8px;
  background: #1f2124;
  width: 100%;
  padding-top: 8px;
  padding: 16px;
  margin: 0;
`

const BettingBetButton = styled.button`
  background: ${HermesMainHover} !important;
  border: 1px solid ${HermesMainHover} !important;
  transition: background-color 0.1s ease-out 0s, border 0.1s ease-out 0s;

  width: 100%;
  height: 45px;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 3px;
  font-weight: 500;
  color: #000;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  display: block;
`

// 배팅 카드 밑 이미지
const BettingBottomBox = styled.div`
  background: #1a1c1f;
  border-radius: 6px;
  margin-top: 8px;

  padding: 0;
  margin-bottom: 9px;
`

const BettingBottomItem = styled.div`
  margin-bottom: 5px;
  cursor: pointer;
`

const SolutionWrap = styled.div`
  width: 100%;
  margin-top: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  // background: #424242;
  color: white;
  font-family: dotum, gulim, Arial, sans-serif !important;
`

const BettingAssetFormWrap = styled.form``

const BettingWhatWrapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingWhatLockIcon = styled(FaLock)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${HermesMainBorder};
`
const SporteBettingCartWrap = styled.div`
  display: block;
  position: fixed;
  z-index: 99998;
  margin-left: 0;
  opacity: 0.85;
  width: 100px;
  box-sizing: border-box;

  ${props =>
    props.isMainPage
      ? `
    right: 3%;
    bottom: 20px;
    `
      : `
    right: 3%;
    bottom: 20px;
    left: calc(50% - 50px);
  `}

  img {
    cursor: pointer;
    vertical-align: middle;
    border: 0;
    box-sizing: border-box;
    width: 100px;
    overflow-clip-margin: content-box;
    overflow: clip;
  }

  span {
    color: #fff;
    position: absolute;
    font-size: 16px;
    font-weight: 700;
    right: 22px;
    bottom: 9px;
    box-sizing: border-box;
  }
`

const SportsBettingCartIcon = styled.img`
  width: 75px;
  height: 75px;
`

const SportsBettingCartNumWrap = styled.div`
  position: absolute;
  top: -15px;
  right: -10px;
  width: 36px;
  height: 36px;
  border-color: ${HermesMainHover2};
  background-color: ${HermesMainHover};
  border-radius: 50%;
`

const SportsBettingCartNum = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  line-height: 20px;
  color: #fff;
`

const SportsBettingCartNumIcon = styled.img`
  width: 35px;
  height: 35px;
`
