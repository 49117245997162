import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { HermesMainText } from '../../utils/palette'
import MobileMiniGameBettingCart from '../MobileLayout/MobileMiniGameBettingCart'

const MobileMiniGameTabs = ({ activeKey }) => {
  const navigate = useNavigate()
  return (
    <>
      <CustomBottomTabs>
        <Tab.Container defaultActiveKey={activeKey}>
          <TabsWrap>
            <Nav className="nav-tabs" style={{ marginBottom: '10px' }}>
              <br />
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="1"
                  onClick={() => {
                    navigate('/mobile/mini-game')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/ntry.png`} alt="" />
                  {/* <span style={{ border: 'solid 1px red' }}>엔트리</span> */}
                  <span>엔트리</span>
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="2"
                  onClick={() => {
                    navigate('/mobile/mini-game/boscore/stat1m')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/boscore.png`} alt="" />
                  <span>보스코어</span>
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="3"
                  onClick={() => {
                    navigate('/mobile/mini-game/named/n/powerball3m')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/named.png`} alt="" />
                  <span>네임드</span>
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="4"
                  onClick={() => {
                    navigate('/mobile/mini-game/sureman/roulette1m')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/sureman.png`} alt="" />
                  <span>슈어맨</span>
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="5"
                  onClick={() => {
                    navigate('/mobile/mini-game/gopick/raccoon')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/gopick.png`} alt="" />
                  <span>고픽</span>
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="9"
                  onClick={() => {
                    navigate('/mobile/mini-game/donghang/powerball')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/donghang.png`} alt="" />
                  <span>동행복권</span>
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="10"
                  onClick={() => {
                    navigate('/mobile/mini-game/next/powerball')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/next.png`} alt="" />
                  <span>넥스트</span>
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="11"
                  onClick={() => {
                    navigate('/mobile/mini-game/bepick/powerball')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/bepick.png`} alt="" />
                  <span>베픽</span>
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="6"
                  onClick={() => {
                    navigate('/mobile/mini-game/crown/sutda')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/crown.png`} alt="" />
                  <span>크라운</span>
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="7"
                  onClick={() => {
                    navigate('/mobile/mini-game/mgm/go-stop')
                  }}
                >
                  <img
                    style={{ width: '25px', height: 'auto' }}
                    src={`${process.env.PUBLIC_URL}/img/main/mgm.png`}
                    alt=""
                  />
                  <span>MGM</span>
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="8"
                  onClick={() => {
                    navigate('/mobile/mini-game/lotus/odd-even')
                  }}
                >
                  <img
                    style={{ width: '20px', height: 'auto' }}
                    src={`${process.env.PUBLIC_URL}/img/main/lotus.png`}
                    alt=""
                  />
                  <span>로투스</span>
                </MiniGameNavLink>
              </MiniGameNavItem>
            </Nav>
          </TabsWrap>
        </Tab.Container>
      </CustomBottomTabs>
      <MobileMiniGameBettingCart />
    </>
  )
}

export default MobileMiniGameTabs

const CustomBottomTabs = styled(BorderedBottomTabs)``

const MiniGameNavLink = styled(NavLink)`
  color: #fff !important;
  height: 50px;
  &.active,
  &.active:focus,
  &.active:hover {
    color: ${HermesMainText} !important;
    background: rgb(44, 52, 59) !important;
    font-weight: 400;
  }
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 10px; 0px;
  img {
    width: 22px;
    height: 18px;
  }
  span {
    display: block;
    min-width: max-content;
  }
`

const MiniGameNavItem = styled(NavItem)`
  width: 25%;
  text-align: center;
  background: rgb(22, 23, 26);
`
