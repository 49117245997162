import isEqual from 'lodash/isEqual'
import moment from 'moment'
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { addBettingCartItem } from '../../../redux/sportsBettingSlice'
import {
  fetchLiveFixtureListAction,
  fetchLiveFixtureMarketAction,
  setFixtureCollapse,
  setFixtureMarketAdditionalOption,
  setFixtureMarketParam,
  setPageNum,
} from '../../../redux/sportsLiveInfoSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import { SportMarketType } from '../../../utils/enums/SportsGame/SportsGameEnums'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'
import { MobileSportEventNotInProgressFixtures } from './MobileSportEventNotInProgressFixtures'
import { MobileSportEventInProgressFixtures } from './MobileSportEventInProgressFixtures'

const MobileSportsLiveContent = () => {
  const dispatch = useDispatch()

  const { totalElements, liveParams, liveMarketParams, liveFixtures } = useSelector(state => {
    const { sportsLiveInfo } = state

    return {
      totalElements: sportsLiveInfo.totalElements,
      liveParams: sportsLiveInfo.liveParams,
      liveMarketParams: sportsLiveInfo.liveMarketParams,
      liveFixtures: sportsLiveInfo.liveFixtures,
    }
  })

  const [liveInProgressFixtures, setLiveInProgressFixtures] = useState({})
  const [liveNotInProgressFixtures, setLiveNotInProgressFixtures] = useState({})

  useEffect(() => {
    const tempLiveInProgressFixtures = {}
    const tempLiveNotInProgressFixtures = {}

    Object.entries(liveFixtures).forEach(([key, event]) => {
      // 현재 이벤트의 fixtures를 분류
      const inProgressFixtures = event.fixtures.filter(fixtureItem => {
        return (
          fixtureItem.fixture.fixtureStatus === 'IN_PROGRESS' || fixtureItem.fixture.fixtureStatus === 'ABOUT_TO_START'
        )
      })

      const notInProgressFixtures = event.fixtures.filter(fixtureItem => {
        return (
          fixtureItem.fixture.fixtureStatus !== 'IN_PROGRESS' && fixtureItem.fixture.fixtureStatus !== 'ABOUT_TO_START'
        )
      })

      // 분류된 fixtures가 존재하면 임시 객체에 추가
      if (inProgressFixtures.length > 0) {
        tempLiveInProgressFixtures[key] = {
          ...event,
          fixtures: inProgressFixtures,
        }
      }

      if (notInProgressFixtures.length > 0) {
        tempLiveNotInProgressFixtures[key] = {
          ...event,
          fixtures: notInProgressFixtures,
        }
      }
    })

    setLiveInProgressFixtures(tempLiveInProgressFixtures)
    setLiveNotInProgressFixtures(tempLiveNotInProgressFixtures)
  }, [liveFixtures])

  const { bettingCartList, bettingOkTimeCriteria } = useSelector(state => {
    const { sportsBetting } = state

    const sportsBettingInfo = sportsBetting.sportsBettingInfos[SportMarketType.LIVE]

    return {
      bettingCartList: sportsBetting.bettingCartList,
      bettingOkTimeCriteria: sportsBettingInfo?.bettingOkTimeCriteria ?? 0,
    }
  })

  const bettingCartListRef = useRef(bettingCartList)

  useEffect(() => {
    bettingCartListRef.current = bettingCartList
  }, [bettingCartList])

  const sportsEventListWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / liveParams.size))
    if (sportsEventListWrapRef.current) {
      sportsEventListWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }, [totalElements, liveParams])

  const onPageChange = pageNumber => {
    dispatch(setPageNum(pageNumber))
  }

  useEffect(() => {
    dispatch(fetchLiveFixtureListAction())
  }, [dispatch, liveParams])

  useEffect(() => {
    dispatch(fetchLiveFixtureMarketAction())
  }, [dispatch, liveMarketParams])

  // 경기 리그 펼치기
  const onClickFixtureCollapseHandler = useCallback(
    key => {
      dispatch(setFixtureCollapse(key))
    },
    [dispatch],
  )

  const [selectedFixtureInfo, setSelectedFixtureInfo] = useState(null)
  const selectedFixtureInfoRef = useRef(selectedFixtureInfo)

  useEffect(() => {
    selectedFixtureInfoRef.current = selectedFixtureInfo
  }, [selectedFixtureInfo])

  const onClickSportFixtureMarketAdditionalOptionHandler = useCallback(
    (eventKey, fixtureData) => {
      const diff = !isEqual(selectedFixtureInfoRef.current, {
        eventKey,
        fixtureId: fixtureData.fixtureId,
      })

      if (diff) {
        dispatch(setFixtureMarketParam(fixtureData.fixtureId))
        dispatch(
          setFixtureMarketAdditionalOption({
            key: eventKey,
            fixtureId: fixtureData.fixtureId,
          }),
        )
        setSelectedFixtureInfo({
          eventKey,
          fixtureId: fixtureData.fixtureId,
        })
      } else {
        dispatch(
          setFixtureMarketAdditionalOption({
            key: eventKey,
            fixtureId: fixtureData.fixtureId,
          }),
        )
        setSelectedFixtureInfo(null)
      }
    },
    [dispatch],
  )

  // 배팅 카트 담기
  const onClickAddBettingCartHandler = useCallback(
    bettingCartItem => {
      dispatch(
        addBettingCartItem({
          ...bettingCartItem,
          sportMarketType: SportMarketType.LIVE,
        }),
      )
    },
    [dispatch],
  )

  return (
    <SportsContentWrap>
      <SportsEventListWrap ref={sportsEventListWrapRef}>
        {Object.keys(liveFixtures) === 0 ? (
          <SportsGameEmptyInfoBox key={`empty-${liveParams.sportId}-${liveParams.locationId}`}>
            해당 조건에 검색되는 경기가 없습니다.
          </SportsGameEmptyInfoBox>
        ) : (
          <>
            {Object.keys(liveInProgressFixtures).length !== 0 && (
              <SportsInProgressWrap>
                {Object.entries(liveInProgressFixtures).map(([key, event]) => {
                  const filteredFixtures = event.fixtures.filter(fixtureItem => {
                    return (
                      fixtureItem.fixture.fixtureVisible &&
                      fixtureItem.fixture.sportVisible &&
                      fixtureItem.fixture.locationVisible &&
                      fixtureItem.fixture.leagueVisible &&
                      fixtureItem.fixture.homeVisible &&
                      fixtureItem.fixture.awayVisible &&
                      (fixtureItem.fixture.fixtureStatus === 'IN_PROGRESS' ||
                        fixtureItem.fixture.fixtureStatus === 'ABOUT_TO_START')
                    )
                  })

                  if (filteredFixtures.length === 0) return null

                  const customEvent = {
                    ...event,
                    fixtures: [...filteredFixtures],
                  }

                  return (
                    <MobileSportEventInProgressFixtures
                      eventKey={key}
                      event={customEvent}
                      onClickFixtureCollapseHandler={onClickFixtureCollapseHandler}
                      onClickSportFixtureMarketAdditionalOptionHandler={
                        onClickSportFixtureMarketAdditionalOptionHandler
                      }
                      onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                    />
                  )
                })}
              </SportsInProgressWrap>
            )}

            {Object.keys(liveNotInProgressFixtures).length !== 0 && (
              <SportsNotInProgressWrap>
                <SportsNotInProgressTextBox>진행예정경기</SportsNotInProgressTextBox>
                {Object.entries(liveNotInProgressFixtures).map(([key, event]) => {
                  const filteredFixtures = event.fixtures.filter(fixtureItem => {
                    return (
                      fixtureItem.fixture.fixtureVisible &&
                      fixtureItem.fixture.sportVisible &&
                      fixtureItem.fixture.locationVisible &&
                      fixtureItem.fixture.leagueVisible &&
                      fixtureItem.fixture.homeVisible &&
                      fixtureItem.fixture.awayVisible &&
                      fixtureItem.fixture.fixtureStatus === 'NSY'
                    )
                  })

                  if (filteredFixtures.length === 0) return null

                  const customEvent = {
                    ...event,
                    fixtures: [...filteredFixtures],
                  }
                  return (
                    <MobileSportEventNotInProgressFixtures
                      eventKey={key}
                      event={customEvent}
                      onClickFixtureCollapseHandler={onClickFixtureCollapseHandler}
                    />
                  )
                })}
              </SportsNotInProgressWrap>
            )}

            <PaginationBox>
              <CustomPagination currentPage={liveParams.page} totalPages={totalPages} onPageChange={onPageChange} />
            </PaginationBox>
          </>
        )}
      </SportsEventListWrap>
    </SportsContentWrap>
  )
}

export default MobileSportsLiveContent

const SportsContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  position: relative;
  padding-top: 45px;
`

const SportsEventListWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 10px;
  }
`

// 경기가 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

// 페이징
const PaginationBox = styled.div`
  width: 100%;
`

const SportsEventWrap = styled.div`
  width: 100%;
  padding: 0 0 15px 0;
  display: flex;
  flex-direction: column;
`

const SportsEventTitleBox = styled.div`
  padding: 0 10px;
  height: 36px;
  width: 100%;
  color: white;
  background: rgba(48, 48, 48, 1);
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const SportsEventTitleTypeImg = styled.img`
  width: auto;
  height: 20px;
`
const SportsEventTitleCountryImg = styled.img`
  width: auto;
  height: 20px;
  margin-left: 5px;
`

const SportsEventTitleCountryInfo = styled.span`
  min-width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleArrowRightIcon = styled(MdOutlineArrowForwardIos)`
  color: ${HermesMainHover2};
  width: 17px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueImg = styled.img`
  width: auto;
  height: 20px;
  margin-left: 5px;
`

const SportsEventTitleLeagueInfo = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SportsEventTitleLeagueCount = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;
  display: inline-block;
`

const SportsEventTitleArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: ${HermesMainHover2};
  width: 20px;
  height: auto;
  cursor: pointer;
  margin-left: auto;
`

const SportsEventTitleArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: ${HermesMainHover2};
  width: 20px;
  height: auto;
  cursor: pointer;
  margin-left: auto;
`

const SportsEventContentBox = styled.div`
  width: 100%;
  min-height: 50px;
  color: white;
  padding: 7px;
  display: flex;
  flex-direction: column;
  border: 2px solid #3a3a3a;
  gap: 2px;
`

const SportsEventContentTopWrap = styled.div`
  width: 100%;
  min-height: 1px;
  color: white;
  margin-bottom: 1px;

  &:not(:first-child) {
    margin-top: 5px;
  }
`

const SportsEventContentTopDate = styled.div`
  border-radius: 3px;
  color: #fff;
  float: left;
  padding: 4px 12px;
  background: ${HermesMainSelect};

  span {
    color: ${HermesMainText};
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 500;
  }
`

const SportsEventContentTopOption = styled.div`
  border-radius: 3px;
  color: ${HermesMainText};
  float: right;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  background: ${HermesMainSelect};

  span {
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      color: #fff;
    }
  }

  &:hover {
    cursor: pointer;
    background: ${HermesMainSelect};
    color: #fff;
  }

  ${props =>
    props.active &&
    `
    cursor: pointer;
    color: #fff;
  `}
`

const SportsEventContentItem = styled.div`
  width: 100%;
  height: 36px;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
`

const SportsEventContentItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);

  border: 1px solid ${HermesMainBorder};
`

const SportsEventContentItemLockIcon = styled(FaLock)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${HermesMainBorder};
`

const SportsEventContentItemMarketName = styled.div`
  min-width: 65px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(48, 48, 48, 1);
  border: 2px solid #2d353c;
  color: white;
  font-weight: 600;
`

const FixtureMarketBetBoxName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }
`

const FixtureMarketBetBoxPrice = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  padding-right: 10px;
  &:hover {
    cursor: pointer;
  }

  img {
    margin-right: 5px;
  }
`

const FixtureMarketBetBox = styled.div`
  width: calc(50% - 39px);
  height: 100%;
  display: flex;
  position: relative;

  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);
  &:hover {
    cursor: pointer;
    background: ${HermesMainSelect};
    color: ${HermesMainText};
  }

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
    `}
`

const SportsEventContentItemPoint = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);

  font-weight: 600;

  &:hover {
    cursor: pointer;
    background: ${HermesMainSelect};
    color: ${HermesMainText};
  }

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
    `}
`

const SportsEventContentItemAwayName = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 10px;
  font-weight: 500;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }
`

const SportsEventContentItemAwayOddValue = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  padding-left: 10px;

  &:hover {
    cursor: pointer;
  }

  img {
    margin-left: 5px;
  }
`

const SportsEventContentItemAwayBox = styled.div`
  width: calc(50% - 39px);
  height: 100%;
  display: flex;
  position: relative;
  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);

  &:hover {
    cursor: pointer;
    background: ${HermesMainSelect};
    color: ${HermesMainText};
  }
  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
    `}
`

const SportsInProgressWrap = styled.div`
  width: 100%;
  display: block;
`

const SportsNotInProgressWrap = styled.div`
  width: 100%;
  display: block;
`

// 진행예정경기 문구
const SportsNotInProgressTextBox = styled.div`
  display: block;
  padding: 20px;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  color: #fff;
  margin-top: 20px;
  background: rgb(29, 30, 33);
`
